const shedTypeKeys = [
    'hardin',
    'rotan',
    'anson',
    'anson-m',
    'forsan',
    'forsan-m',
    'menard',
    'menard-m',
    'dryden'
] as const

interface ShedTypeFixedCollisionMesh {
    position: {
        x: number,
        y: number,
        z: number
    }
    dimensions: {
        widthMeter: number
        heightMeter: number
        depthMeter: number
    }
    description?: string
}

interface ShedType {
    /**
     * The name visible for the configurator users
     */
    name: string
    /**
     * Must be unique, for internal use, radio button value for example
     */
    key: typeof shedTypeKeys[number]
    dimensions: string
    optionImageFilename?: string
    widthMeter: number
    lengthMeter: number
    eaveHeightMeter: number
    ridgeHeightMeter: number
    /**
     * Excluding vat
     */
    basePriceEuro: number
    /**
     * The "spanten"
     */
    fixedCollisionMeshes: ShedTypeFixedCollisionMesh[]
    isInformalCareHome: boolean
}

const defaultFeaturesPrice = 7850

const shedTypes: ShedType[] = [{
    name: 'Rotan',
    key: 'rotan',
    dimensions: '3m x 8m',
    optionImageFilename: 'shed-type/rotan.png',
    widthMeter: 3,
    lengthMeter: 8,
    eaveHeightMeter: 3,
    ridgeHeightMeter: 3.546,
    basePriceEuro: 33500 - defaultFeaturesPrice,
    fixedCollisionMeshes: [],
    isInformalCareHome: false
}, {
    name: 'Hardin',
    key: 'hardin',
    dimensions: '3m x 8m',
    optionImageFilename: 'shed-type/hardin.png',
    widthMeter: 3,
    lengthMeter: 8,
    eaveHeightMeter: 3,
    ridgeHeightMeter: 4.5,
    basePriceEuro: 33500 - defaultFeaturesPrice,
    fixedCollisionMeshes: [],
    isInformalCareHome: false
}, {
    name: 'Anson',
    key: 'anson',
    dimensions: '5m x 10m',
    optionImageFilename: 'shed-type/anson.png',
    widthMeter: 5,
    lengthMeter: 10,
    eaveHeightMeter: 3,
    ridgeHeightMeter: 5,
    basePriceEuro: 49500 - defaultFeaturesPrice,
    fixedCollisionMeshes: [],
    isInformalCareHome: false
}, {
    name: 'Anson - M',
    key: 'anson-m',
    dimensions: '5m x 10m',
    optionImageFilename: 'shed-type/anson-m.png',
    widthMeter: 5,
    lengthMeter: 10,
    eaveHeightMeter: 3,
    ridgeHeightMeter: 5,
    basePriceEuro: 49500 - defaultFeaturesPrice,
    fixedCollisionMeshes: [],
    isInformalCareHome: true
}, {
    name: 'Forsan',
    key: 'forsan',
    dimensions: '7.5m x 10m',
    optionImageFilename: 'shed-type/forsan.png',
    widthMeter: 7.5,
    lengthMeter: 10,
    eaveHeightMeter: 3,
    ridgeHeightMeter: 5,
    basePriceEuro: 57500 - defaultFeaturesPrice,
    fixedCollisionMeshes: [],
    isInformalCareHome: false
}, {
    name: 'Forsan - M',
    key: 'forsan-m',
    dimensions: '7.5m x 10m',
    optionImageFilename: 'shed-type/forsan-m.png',
    widthMeter: 7.5,
    lengthMeter: 10,
    eaveHeightMeter: 3,
    ridgeHeightMeter: 5,
    basePriceEuro: 57500 - defaultFeaturesPrice,
    fixedCollisionMeshes: [],
    isInformalCareHome: true
}, {
    name: 'Menard',
    key: 'menard',
    dimensions: '8m x 12,5m',
    optionImageFilename: 'shed-type/menard.png',
    widthMeter: 8,
    lengthMeter: 12.5,
    eaveHeightMeter: 3,
    ridgeHeightMeter: 5,
    basePriceEuro: 67500 - defaultFeaturesPrice,
    fixedCollisionMeshes: [],
    isInformalCareHome: false
}, {
    name: 'Menard - M',
    key: 'menard-m',
    dimensions: '8m x 12,5m',
    optionImageFilename: 'shed-type/menard-m.png',
    widthMeter: 8,
    lengthMeter: 12.5,
    eaveHeightMeter: 3,
    ridgeHeightMeter: 5,
    basePriceEuro: 67500 - defaultFeaturesPrice,
    fixedCollisionMeshes: [],
    isInformalCareHome: true
}, {
    name: 'Dryden',
    key: 'dryden',
    dimensions: '10m x 15m',
    optionImageFilename: 'shed-type/dryden.png',
    widthMeter: 10,
    lengthMeter: 15,
    eaveHeightMeter: 3,
    ridgeHeightMeter: 4.76,
    basePriceEuro: 80500 - defaultFeaturesPrice,
    fixedCollisionMeshes: [],
    isInformalCareHome: false
}]

// add spanten collision meshes
shedTypes.forEach(shedType => {
    if (shedType.key === 'dryden') {
        shedType.fixedCollisionMeshes.push({
            position: {
                x: 0,
                y: shedType.eaveHeightMeter / 2,
                z: -(shedType.lengthMeter) * 0.333
            },
            dimensions: {
                widthMeter: 0.2,
                heightMeter: shedType.eaveHeightMeter,
                depthMeter: 0.2
            }
        })
        shedType.fixedCollisionMeshes.push({
            position: {
                x: 0,
                y: shedType.eaveHeightMeter / 2,
                z: -(shedType.lengthMeter) * 0.666
            },
            dimensions: {
                widthMeter: 0.2,
                heightMeter: shedType.eaveHeightMeter,
                depthMeter: 0.2
            }
        })
        shedType.fixedCollisionMeshes.push({
            position: {
                x: shedType.widthMeter,
                y: shedType.eaveHeightMeter / 2,
                z: -(shedType.lengthMeter) * 0.333
            },
            dimensions: {
                widthMeter: 0.2,
                heightMeter: shedType.eaveHeightMeter,
                depthMeter: 0.2
            }
        })
        shedType.fixedCollisionMeshes.push({
            position: {
                x: shedType.widthMeter,
                y: shedType.eaveHeightMeter / 2,
                z: -(shedType.lengthMeter) * 0.666
            },
            dimensions: {
                widthMeter: 0.2,
                heightMeter: shedType.eaveHeightMeter,
                depthMeter: 0.2
            }
        })
    } else {
        shedType.fixedCollisionMeshes.push({
            position: {
                x: 0,
                y: shedType.eaveHeightMeter / 2,
                z: -(shedType.lengthMeter) / 2
            },
            dimensions: {
                widthMeter: 0.2,
                heightMeter: shedType.eaveHeightMeter,
                depthMeter: 0.2
            }
        })
        shedType.fixedCollisionMeshes.push({
            position: {
                x: shedType.widthMeter,
                y: shedType.eaveHeightMeter / 2,
                z: -(shedType.lengthMeter) / 2
            },
            dimensions: {
                widthMeter: 0.2,
                heightMeter: shedType.eaveHeightMeter,
                depthMeter: 0.2
            }
        })
    }
})

export default shedTypes
export { shedTypeKeys as actualShedTypeKeys }
export type { ShedType, shedTypeKeys }
