import { ConfigurationInterface } from '../../configuration'
import React, { FC, ReactNode, useEffect, useMemo, useRef } from 'react'
import shedTypes, { ShedType } from '../../shed-types'
import {
    Box3,
    BoxGeometry,
    DoubleSide,
    MathUtils, Mesh, MeshBasicMaterial,
    MeshPhongMaterial,
    RepeatWrapping, Texture,
    Vector2, Color
} from 'three'
import { useDevToolsContext } from '../../dev-tools/context'
import Wall from './wall'
import Roof from './roof'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { useLoader } from '@react-three/fiber'
import colorsAndFinishes from '../../colors-and-finishes'
import RainGutter from './rain-gutter'
import renderSettings from '../settings'
import RainPipes from './rain-pipes'
import ConcreteFloor from './concrete-floor'
import { CollisionMeshUserData } from '../colliding-meshes/mesh-user-data'
import { OBB } from 'three/examples/jsm/math/OBB'
import { Text } from '@react-three/drei'
import { InitialAnimationProps } from '../animator'

interface Props {
    configuration: ConfigurationInterface
    setConfiguration: (configuration: ConfigurationInterface) => void
    showDimensions: boolean
    hideShed: boolean
}

const Shed:FC<Props> = (props) => {
    const { options: devToolsOptions } = useDevToolsContext()
    const shedType = useMemo(() => {
        return shedTypes.find(type => type.key === props.configuration.shedType) as ShedType
    }, [props.configuration.shedType])
    const configurationRef = useRef(props.configuration)

    const wallNormalMap = useLoader(TextureLoader, `${import.meta.env.VITE_BASE_URL}/static/3d/textures/falk-1160-wb-normal-map.png`)
    wallNormalMap.repeat.set(1, 1)
    wallNormalMap.wrapS = RepeatWrapping
    wallNormalMap.wrapT = RepeatWrapping

    const wallTexture = useLoader(TextureLoader, `${import.meta.env.VITE_BASE_URL}/static/3d/textures/falk-1060-wb.png`)
    wallTexture.repeat.set(1, 1)
    wallTexture.wrapS = RepeatWrapping
    wallTexture.wrapT = RepeatWrapping

    const frontAndBackBricksTexture = useLoader(TextureLoader, `${import.meta.env.VITE_BASE_URL}/static/3d/textures/bricks-2.png`)
    frontAndBackBricksTexture.wrapS = RepeatWrapping
    frontAndBackBricksTexture.wrapT = RepeatWrapping

    const leftAndRightBricksTexture = new Texture()
    leftAndRightBricksTexture.copy(frontAndBackBricksTexture)

    const frontAndBackConcreteTexture = useLoader(TextureLoader, `${import.meta.env.VITE_BASE_URL}/static/3d/textures/concrete.jpg`)
    frontAndBackConcreteTexture.wrapS = RepeatWrapping
    frontAndBackConcreteTexture.wrapT = RepeatWrapping

    const leftAndRightConcreteTexture = new Texture()
    leftAndRightConcreteTexture.copy(frontAndBackConcreteTexture)

    const frontAndBackGravelTexture = useLoader(TextureLoader, `${import.meta.env.VITE_BASE_URL}/static/3d/textures/perlin-black-and-white.png`)
    frontAndBackGravelTexture.wrapS = RepeatWrapping
    frontAndBackGravelTexture.wrapT = RepeatWrapping

    const leftAndRightGravelTexture = new Texture()
    leftAndRightGravelTexture.copy(frontAndBackGravelTexture)

    const debugTexture = useLoader(TextureLoader, `${import.meta.env.VITE_BASE_URL}/static/3d/textures/texture-debug.jpg`)
    debugTexture.repeat.set(1, 0.2)
    debugTexture.wrapS = RepeatWrapping
    debugTexture.wrapT = RepeatWrapping

    const rainGutterInitialAnimationProps: InitialAnimationProps = {
        from: {
            position: {
                y: -1
            }
        },
        to: {
            position: {
                y: 0
            }
        },
        delay: 1700,
        duration: 1000
    }

    const rainPipesInitialAnimationProps: InitialAnimationProps = {
        from: {
            position: {
                y: -4
            }
        },
        to: {
            position: {
                y: 0
            }
        },
        delay: 1700,
        duration: 1000
    }

    const fixedCollisionMeshes:ReactNode[] = useMemo(() => {
        let meshes:ReactNode[] = []
        shedType.fixedCollisionMeshes.forEach((fixedCollisionMeshProps, index) => {
            const mesh = new Mesh(
                new BoxGeometry(fixedCollisionMeshProps.dimensions.widthMeter, fixedCollisionMeshProps.dimensions.heightMeter, fixedCollisionMeshProps.dimensions.depthMeter),
                new MeshBasicMaterial({
                    color: '#f000ff',
                    wireframe: true
                })
            )
            mesh.geometry.computeBoundingBox()
            const userData: CollisionMeshUserData = {
                obb: new OBB().fromBox3(
                    mesh.geometry.boundingBox as Box3
                ),
                isCollisionMesh: true
            }
            mesh.userData = userData
            mesh.visible = devToolsOptions.showCollisionMeshes
            mesh.position.set(fixedCollisionMeshProps.position.x, fixedCollisionMeshProps.position.y, fixedCollisionMeshProps.position.z)
            meshes.push(<primitive object={mesh} key={index}/>)
        })
        return meshes
    }, [devToolsOptions.showCollisionMeshes, props.configuration.shedType])

    useEffect(() => {
        configurationRef.current = props.configuration
    }, [props.configuration])

    const materials = useMemo(() => {
        const roofColorOrFinish = colorsAndFinishes.roof.find(option => option.key === props.configuration.colorsAndFinish.roof)
        const wallColorOrFinish = colorsAndFinishes.wall.find(option => option.key === props.configuration.colorsAndFinish.wall)
        const detailingColorOrFinish = colorsAndFinishes.detailing.find(option => option.key === props.configuration.colorsAndFinish.detailing)
        const concretePlinthColorOrFinish = colorsAndFinishes.concretePlinth.find(option => option.key === props.configuration.colorsAndFinish.concretePlinth)
        const windFeatherColorOrFinish = colorsAndFinishes.windFeather.find(option => option.key === props.configuration.colorsAndFinish.windFeather)

        let concretePlinthLeftAndRightMaterial = new MeshPhongMaterial({ wireframe: devToolsOptions.showWireframes })
        let concretePlinthFrontAndBackMaterial = new MeshPhongMaterial({ wireframe: devToolsOptions.showWireframes })

        if (concretePlinthColorOrFinish !== undefined) {
            if (concretePlinthColorOrFinish.key === 'redStoneMotif') {
                frontAndBackBricksTexture.repeat.set(shedType.widthMeter * 1.3, 0.7)
                leftAndRightBricksTexture.repeat.set(shedType.lengthMeter * 1.3, 0.7)

                concretePlinthLeftAndRightMaterial.map = leftAndRightBricksTexture
                concretePlinthFrontAndBackMaterial.map = frontAndBackBricksTexture
            } else if (concretePlinthColorOrFinish.key === 'concreteGrey') {
                concretePlinthLeftAndRightMaterial.color = new Color('#aaaaaa')
                concretePlinthLeftAndRightMaterial.color = new Color('#aaaaaa')

                frontAndBackConcreteTexture.repeat.set(shedType.widthMeter, 0.7)
                leftAndRightConcreteTexture.repeat.set(shedType.lengthMeter, 0.7)

                concretePlinthLeftAndRightMaterial.map = leftAndRightConcreteTexture
                concretePlinthFrontAndBackMaterial.map = frontAndBackConcreteTexture
            } else if (concretePlinthColorOrFinish.key === 'anthraciteGravel') {
                concretePlinthLeftAndRightMaterial.color = new Color('#aaaaaa')
                concretePlinthLeftAndRightMaterial.color = new Color('#aaaaaa')

                frontAndBackGravelTexture.repeat.set(shedType.widthMeter * 4, 4)
                leftAndRightGravelTexture.repeat.set(shedType.lengthMeter * 4, 4)

                concretePlinthLeftAndRightMaterial.map = leftAndRightGravelTexture
                concretePlinthFrontAndBackMaterial.map = frontAndBackGravelTexture
            }
        }

        return {
            wall: new MeshPhongMaterial({
                color: wallColorOrFinish?.sceneHexColor ?? '#ff0000',
                wireframe: devToolsOptions.showWireframes,
                shininess: 200,
                normalMap: wallNormalMap,
                normalScale: new Vector2(2, 1),
                map: wallTexture
            }),
            concreteFloor: new MeshPhongMaterial({
                color: '#666666',
                wireframe: devToolsOptions.showWireframes,
                side: DoubleSide
            }),
            concretePlinthLeftAndRight: concretePlinthLeftAndRightMaterial,
            concretePlinthFrontAndBack: concretePlinthFrontAndBackMaterial,
            concretePlinthCoverBendedMetal: new MeshPhongMaterial({
                color: wallColorOrFinish?.sceneHexColor ?? '#ff0000',
                wireframe: devToolsOptions.showWireframes,
                shininess: 200
            }),
            wallCornerBendedMetal: new MeshPhongMaterial({
                color: wallColorOrFinish?.sceneHexColor ?? '#ff0000',
                wireframe: devToolsOptions.showWireframes,
                shininess: 200
            }),
            windFeatherBendedMetal: new MeshPhongMaterial({
                color: windFeatherColorOrFinish?.sceneHexColor ?? '#ff0000',
                wireframe: devToolsOptions.showWireframes,
                shininess: 200,
                side: DoubleSide
            }),
            roofRidgeCoverBendedMetal: new MeshPhongMaterial({
                color: roofColorOrFinish?.sceneHexColor ?? '#ff0000',
                wireframe: devToolsOptions.showWireframes,
                shininess: 200,
                side: DoubleSide
            }),
            roof: new MeshPhongMaterial({
                color: roofColorOrFinish?.sceneHexColor ?? '#ff0000',
                wireframe: devToolsOptions.showWireframes,
                shininess: 300
            }),
            rainGutter: new MeshPhongMaterial({
                color: '#222222',
                wireframe: devToolsOptions.showWireframes,
                shininess: 200,
            }),
            rainPipes: new MeshPhongMaterial({
                color: '#222222',
                wireframe: devToolsOptions.showWireframes,
                shininess: 200,
            }),
            detailing: new MeshPhongMaterial({
                color: detailingColorOrFinish?.sceneHexColor ?? '#ff0000',
                wireframe: devToolsOptions.showWireframes,
                shininess: 200
            })
        }
    }, [devToolsOptions.showWireframes,
        wallNormalMap,
        wallTexture,
        props.configuration.colorsAndFinish.roof,
        props.configuration.colorsAndFinish.wall,
        props.configuration.colorsAndFinish.detailing,
        props.configuration.colorsAndFinish.concretePlinth,
        props.configuration.colorsAndFinish.windFeather,
        props.configuration.shedType
    ])

    const roofCornerDegrees = useMemo(() => {
        return 90 - (MathUtils.radToDeg(Math.atan((shedType.widthMeter / 2) / (shedType.ridgeHeightMeter - shedType.eaveHeightMeter))))
    }, [shedType])

    const sharedWallProps = useMemo(() => {
        return {
            wallMaterial: materials.wall,
            concretePlinthFrontAndBackMaterial: materials.concretePlinthFrontAndBack,
            concretePlinthLeftAndRightMaterial: materials.concretePlinthLeftAndRight,
            concretePlinthCoverBendedMetalMaterial: materials.concretePlinthCoverBendedMetal,
            wallCornerBendedMetalMaterial: materials.wallCornerBendedMetal,
            setConfiguration: props.setConfiguration,
            configuration: props.configuration,
            roofCornerDegrees,
            showDimensions: props.showDimensions,
            detailingMaterial: materials.detailing
        }
    }, [props.configuration, devToolsOptions.showWireframes, props.showDimensions])

    return <group name="Shed"
        position={[-(shedType.widthMeter / 2), props.hideShed ? -10 : 0, shedType.lengthMeter / 2]}
        userData={{ isShedRootGroup: true }}
        // visible={!props.hideShed}
    >
        {fixedCollisionMeshes}
        <group
            name="Concrete floor"
            position={[(shedType.widthMeter / 2), -0.001, -(shedType.lengthMeter / 2)]}
        >
            <ConcreteFloor
                shedWidthMeter={shedType.widthMeter}
                shedLengthMeter={shedType.lengthMeter}
                material={materials.concreteFloor}
            />
        </group>
        <group name={'Front walll'}>
            <Wall
                {...sharedWallProps}
                side={'front'}
                widthMeter={shedType.widthMeter}
                ridgeHeightMeter={shedType.ridgeHeightMeter}
                eaveHeightMeter={shedType.eaveHeightMeter}
                features={props.configuration.features.filter(feature => feature.side === 'front')}
            />
        </group>
        <group
            name={'Right wall'}
            rotation={[0, Math.PI / 2, 0]}
            position={[shedType.widthMeter, 0, 0]}
        >
            <Wall
                {...sharedWallProps}
                side={'right'}
                widthMeter={shedType.lengthMeter}
                ridgeHeightMeter={shedType.ridgeHeightMeter}
                eaveHeightMeter={shedType.eaveHeightMeter}
                features={props.configuration.features.filter(feature => feature.side === 'right')}
            />
        </group>
        <group
            name={'Right wall rain gutter'}
            position={[
                shedType.widthMeter - (renderSettings.concretePlinth.thicknessMeter - renderSettings.wall.thicknessMeter - (renderSettings.rainGutter.distanceFromWallMm / 1000)),
                shedType.eaveHeightMeter,
                -(shedType.lengthMeter / 2)
            ]}
            scale={[-1, 1, 1]}
        >
            <group userData={{ initialAnimation: rainGutterInitialAnimationProps }}>
                <RainGutter lengthMeter={shedType.lengthMeter + (renderSettings.roof.frontAndBackOverhangMeter * 2) + (renderSettings.rainGutter.extraLengthMm / 1000)} material={materials.rainGutter} />
            </group>
        </group>
        <group
            name={'Right wall rain pipes'}
            position={[shedType.widthMeter + (renderSettings.rainPipes.distanceFromConcretePlinthMm / 1000),
                shedType.eaveHeightMeter,
                -(shedType.lengthMeter / 2)]}
            scale={[-1, 1, 1]}
        >
            <group userData={{ initialAnimation: rainPipesInitialAnimationProps }}>
                <RainPipes shedLengthMeter={shedType.lengthMeter} eaveHeightMeter={shedType.eaveHeightMeter} material={materials.rainPipes} />
            </group>
        </group>

        <group
            name={'Left wall'}
            rotation={[0, -Math.PI / 2, 0]}
            position={[0, 0, - shedType.lengthMeter]}
        >
            <Wall
                {...sharedWallProps}
                side={'left'}
                widthMeter={shedType.lengthMeter}
                ridgeHeightMeter={shedType.ridgeHeightMeter}
                eaveHeightMeter={shedType.eaveHeightMeter}
                features={props.configuration.features.filter(feature => feature.side === 'left')}
            />
        </group>
        <group
            name={'Left wall rain gutter'}
            position={[renderSettings.concretePlinth.thicknessMeter - renderSettings.wall.thicknessMeter - (renderSettings.rainGutter.distanceFromWallMm / 1000),
                shedType.eaveHeightMeter,
                -(shedType.lengthMeter / 2)
            ]}
        >
            <group userData={{ initialAnimation: rainGutterInitialAnimationProps }}>
                <RainGutter lengthMeter={shedType.lengthMeter + (renderSettings.roof.frontAndBackOverhangMeter * 2) + (renderSettings.rainGutter.extraLengthMm / 1000)} material={materials.rainGutter} />
            </group>
        </group>
        <group
            name={'Left wall rain pipes'}
            position={[-(renderSettings.rainPipes.distanceFromConcretePlinthMm / 1000),
                shedType.eaveHeightMeter,
                -(shedType.lengthMeter / 2)
            ]}
        >
            <group userData={{ initialAnimation: rainPipesInitialAnimationProps }}>
                <RainPipes shedLengthMeter={shedType.lengthMeter} eaveHeightMeter={shedType.eaveHeightMeter} material={materials.rainPipes} />
            </group>
        </group>

        <group
            name={'Back wall'}
            rotation={[0, Math.PI, 0]}
            position={[shedType.widthMeter, 0, -shedType.lengthMeter]}
        >
            <Wall
                {...sharedWallProps}
                side={'back'}
                widthMeter={shedType.widthMeter}
                ridgeHeightMeter={shedType.ridgeHeightMeter}
                eaveHeightMeter={shedType.eaveHeightMeter}
                features={props.configuration.features.filter(feature => feature.side === 'back')}
            />
        </group>

        <group name={'Roof'}>
            <Roof
                material={materials.roof}
                shedWidthMeter={shedType.widthMeter}
                shedLengthMeter={shedType.lengthMeter}
                shedEaveHeightMeter={shedType.eaveHeightMeter}
                shedRidgeHeightMeter={shedType.ridgeHeightMeter}
                materialTypeKey={props.configuration.roofMaterialType}
                windFeatherMaterial={materials.windFeatherBendedMetal}
                roofRidgeCoverBendedMetalMaterial={materials.roofRidgeCoverBendedMetal}
            />
        </group>
    </group>
}

export default Shed
