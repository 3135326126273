import { createTheme } from '@mui/material/styles'
//import type {} from '@mui/lab/themeAugmentation'

const theme = createTheme({
    palette: {
        primary: {
            main: '#7cb72c',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#189A84',
            contrastText: '#ffffff'
        },
    },
    typography: {
        fontSize: 13,
        // fontFamily: 'MontserratVariable',
        h1: {
            fontSize: '36ox',
            fontWeight: 800,
        },
        h2: {
            fontSize: '30px',
            fontWeight: 600,
        },
        h3: {
            fontSize: '24px',
            fontWeight: 600,
        },
        h6: {
            fontSize: '23px'
        },
        subtitle2: {
            fontSize: '13px',
            lineHeight: 1.2
        },
        body1: {
            fontSize: '15px'
        }
    },
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '22px'
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    fontSize: '200% !important'
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    fontSize: '14px'
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '12px'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                text: {
                    fontSize: '13px',
                },
                contained: {
                    fontSize: '13px',
                    background: 'linear-gradient(75deg, #7cb72c, #189A84)',
                    borderRadius: 20
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 0
                }
            }
        },
        MuiPopper: {
            defaultProps: {
                disablePortal: true // because shadowdom
            }
        },
        MuiPopover: {
            defaultProps: {
                disablePortal: true // because shadowdom
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    // border: '1px #e5e5e5 solid',
                    boxShadow: 'none',
                    ':first-of-type': {
                        borderRadius: 0,
                    },
                    ':last-of-type': {
                        borderRadius: 0,
                    }
                },

            }
        },
        MuiDialog: {
            defaultProps: {
                disablePortal: true // because shadowdom
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    backgroundColor: '#e5e5e5',
                    '&:hover': {
                        backgroundColor: '#d5d5d5',
                    },
                    '&.Mui-expanded': {
                        backgroundColor: '#7cb72c',
                        color: '#fff',
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            color: '#fff'
                        }
                    }
                }
            }
        }
    },
})

export default theme
