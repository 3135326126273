import { FC, ReactNode, useMemo } from 'react'
import { ThreeEvent } from '@react-three/fiber/dist/declarations/src/core/events'
import { useDevToolsContext } from '../../dev-tools/context'
import { useInteractableObjectContext } from '../../interactable-object/context'
import { MeshPhongMaterial } from 'three'
import { Text } from '@react-three/drei'
import { degToRad } from 'three/src/math/MathUtils'

interface Props {
    visible: boolean
    shedWithMeter: number
    shedLengthMeter: number
}

const extraSpacePerSide = 6
const textSpaceFromSides = 1.5
const distanceBetweenTexts = 3
const textDistanceFromFloor = 0.01
const textColor = '#ffffff'

const FloorPlane:FC<Props> = (props) => {
    const { options } = useDevToolsContext()
    const { selectedObject, setSelectedObject } = useInteractableObjectContext()

    const clickHandler = (event: ThreeEvent<MouseEvent>) => {
        if (options.logEventsToConsole) {
            console.log('onClick', event)
        }
        if (selectedObject !== null) {
            setSelectedObject(null)
        }
    }

    const materials = useMemo(() => {
        const materials = []

        const otherSidesMaterial = new MeshPhongMaterial({ color: '#626262' })
        const topMaterial = new MeshPhongMaterial({ color: '#707070', shininess: 30 })

        materials.push(otherSidesMaterial)
        materials.push(otherSidesMaterial)
        materials.push(topMaterial)
        materials.push(otherSidesMaterial)
        materials.push(otherSidesMaterial)
        materials.push(otherSidesMaterial)

        return materials
    }, [])

    // const textElements:ReactNode[] = useMemo(() => {
    //     const elements:ReactNode[] = []
    //
    //     const sides = ['front', 'right', 'back', 'left']
    //     sides.forEach(side => {
    //         let availableWidth, amount, spaceBetween
    //         switch (side) {
    //             case 'right' :
    //                 availableWidth = (props.shedLengthMeter + (extraSpacePerSide * 2)) - (textSpaceFromSides * 2)
    //                 amount = Math.ceil(availableWidth / distanceBetweenTexts)
    //                 spaceBetween = availableWidth / amount
    //
    //                 for (let i = 0; i <= amount; i ++) {
    //                     elements.push(<Text
    //                         textAlign="center"
    //                         color={textColor}
    //                         fontSize={0.3}
    //                         rotation={[0, degToRad(90), 0]}
    //                         position={[
    //                             ((props.shedWithMeter / 2) + extraSpacePerSide + textDistanceFromFloor),
    //                             -0.18,
    //                             (i * spaceBetween) - (availableWidth / 2)
    //                         ]}
    //                     >rechterzijde</Text>)
    //                 }
    //                 break
    //
    //             case 'left' :
    //                 availableWidth = (props.shedLengthMeter + (extraSpacePerSide * 2)) - (textSpaceFromSides * 2)
    //                 amount = Math.ceil(availableWidth / distanceBetweenTexts)
    //                 spaceBetween = availableWidth / amount
    //
    //                 for (let i = 0; i <= amount; i ++) {
    //                     elements.push(<Text
    //                         textAlign="center"
    //                         color={textColor}
    //                         fontSize={0.3}
    //                         rotation={[0, degToRad(-90), 0]}
    //                         position={[
    //                             -((props.shedWithMeter / 2) + extraSpacePerSide + textDistanceFromFloor),
    //                             -0.18,
    //                             (i * spaceBetween) - (availableWidth / 2)
    //                         ]}
    //                     >linkerzijde</Text>)
    //                 }
    //                 break
    //             case 'back' :
    //                 availableWidth = (props.shedWithMeter + (extraSpacePerSide * 2)) - (textSpaceFromSides * 2)
    //                 amount = Math.ceil(availableWidth / distanceBetweenTexts)
    //                 spaceBetween = availableWidth / amount
    //
    //                 for (let i = 0; i <= amount; i ++) {
    //                     elements.push(<Text
    //                         textAlign="center"
    //                         color={textColor}
    //                         fontSize={0.3}
    //                         rotation={[0, degToRad(180), 0]}
    //                         position={[
    //                             (i * spaceBetween) - (availableWidth / 2),
    //                             -0.18,
    //                             -((props.shedLengthMeter / 2) + extraSpacePerSide + textDistanceFromFloor)
    //                         ]}
    //                     >achterzijde</Text>)
    //                 }
    //                 break
    //             case 'front' :
    //                 availableWidth = (props.shedWithMeter + (extraSpacePerSide * 2)) - (textSpaceFromSides * 2)
    //                 amount = Math.ceil(availableWidth / distanceBetweenTexts)
    //                 spaceBetween = availableWidth / amount
    //
    //                 for (let i = 0; i <= amount; i ++) {
    //                     elements.push(<Text
    //                         textAlign="center"
    //                         color={textColor}
    //                         fontSize={0.3}
    //                         position={[
    //                             (i * spaceBetween) - (availableWidth / 2),
    //                             -0.18,
    //                             (props.shedLengthMeter / 2) + extraSpacePerSide + textDistanceFromFloor
    //                         ]}
    //                     >voorzijde</Text>)
    //                 }
    //                 break
    //         }
    //     })
    //
    //     return elements
    // }, [props.shedWithMeter, props.shedLengthMeter])

    return <>
        <group visible={props.visible}>
            <mesh
                receiveShadow
                onClick={clickHandler}
                position={[0, -0.2, 0]}
                material={materials}
            >
                <boxGeometry args={[40, 0.4, 40]} />
            </mesh>
            {/*{textElements}*/}
        </group>

    </>
}

export default FloorPlane
