import { FC, useState, ChangeEvent } from 'react'
import {
    Button,
    DialogContent,
    Dialog,
    DialogTitle,
    DialogActions,
    IconButton,
    Grid,
    TextField,
    Typography,
    Box
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { ConfigurationInterface } from '../configuration'
import URI from 'urijs'
import { TextFieldElement, FormContainer } from 'react-hook-form-mui'

class UserInput {
    nameOrCompanyName = ''
    contactPerson = ''
    email = ''
    telephone = ''
    streetAndHouseNumber = ''
    zip = ''
    city = ''
    notes = ''
}

interface Props {
    show: boolean
    onClose: () => void
    configuration: ConfigurationInterface
    renderScreenshotBlob: Blob
    thankYouPageUrl: string|null
    iframeParentUrl: string|null
}

interface ShedRequestFields {
    shed_screenshot: string
    configuration_json: string
    // customer fields
    customer_name_or_company_name: string
    customer_contact_person: string
    customer_email: string
    customer_telephone: string
    customer_street_and_house_number: string
    customer_zip: string
    customer_city: string
    customer_notes: string
    requested_from_url: string
}

const OrderShedPopup:FC<Props> = (props) => {
    const [userInput, setUserInput] = useState<UserInput>(new UserInput())
    const [formIsSubmitting, setFormIsSubmitting] = useState(false)
    const [formErrorMessage, setFormErrorMessage] = useState<null|string>(null)
    const [shedRequestIsSent, setShedRequestIsSent] = useState(false)
    const [viewShedRequestUrl, setViewShedRequestUrl] = useState<null|string>(null)

    const handleOrderButtonClick = async () => {
        setFormIsSubmitting(true)
        setFormErrorMessage(null)
        try {
            // upload file first
            const filesApiUrl = import.meta.env.VITE_DUBLO_PUBLIC_API_URL + '/files'
            const formData = new FormData()
            formData.append('title', 'configuration-screenshot.png')
            formData.append('folder', import.meta.env.VITE_PUBLIC_API_SHED_SCREENSHOTS_FOLDER_UUID)
            formData.append('file', props.renderScreenshotBlob)
            const fileUploadResponse = await fetch(filesApiUrl, {
                method: 'POST',
                body: formData
            })

            const fileUploadResponseJson = await fileUploadResponse.json()
            const fileUuid = fileUploadResponseJson.data.id

            const body:ShedRequestFields = {
                shed_screenshot: fileUuid,
                configuration_json: JSON.stringify(props.configuration),
                customer_name_or_company_name: userInput.nameOrCompanyName,
                customer_contact_person: userInput.contactPerson,
                customer_email: userInput.email,
                customer_telephone: userInput.telephone,
                customer_street_and_house_number: userInput.streetAndHouseNumber,
                customer_zip: userInput.zip,
                customer_city: userInput.city,
                customer_notes: userInput.notes,
                requested_from_url: props.iframeParentUrl !== null ? props.iframeParentUrl : window.location.href
            }

            const apiUrl = import.meta.env.VITE_DUBLO_PUBLIC_API_URL + '/items/shed_requests'
            const response = await fetch(apiUrl, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(body)
            })

            if (response.status === 200) {
                if (props.thankYouPageUrl !== null) {
                    if (props.iframeParentUrl !== null) {
                        parent.postMessage({
                            redirectToUrl: props.thankYouPageUrl
                        }, '*')
                    } else {
                        window.location.href = props.thankYouPageUrl
                    }
                } else {
                    props.onClose()
                    setShedRequestIsSent(true)
                    const responseJson = await response.json()
                    const uri = new URI(responseJson.data.requested_from_url)
                    uri.setQuery({ 'shed-request-uuid': responseJson.data.id })
                    const viewConfigurationUrl = uri.toString()
                    setViewShedRequestUrl(viewConfigurationUrl)
                }
            } else {
                setFormErrorMessage('Er is helaas iets fout gegaan tijdens het versturen van uw aanvraag, probeer het nogmaals of neem contact met ons op.')
            }
        } catch (error) {
            console.log(error)
            setFormErrorMessage('Er is helaas iets fout gegaan tijdens het versturen van uw aanvraag, probeer het nogmaals of neem contact met ons op.')
        } finally {
            setFormIsSubmitting(false)
        }
    }

    const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setUserInput({
            ...userInput,
            [event.target.name]: event.target.value
        })
    }

    const closeShedRequestIsSentDialogHandler = () => {
        setShedRequestIsSent(false)
        props.onClose()
    }

    const closeFormErrorDialogHandler = () => {
        setFormErrorMessage(null)
    }

    return <>
        <Dialog
            open={shedRequestIsSent}
            onClose={closeShedRequestIsSentDialogHandler}
        >
            <DialogContent>
                <Typography>Bedankt, uw aanvraag is verstuurd. U ontvangt op het door u opgegeven e-mailadres een bevestiging.</Typography>
                {viewShedRequestUrl !== null &&
                    <>
                        <Typography marginTop={1}>Middels <a style={{color: 'inherit'}} target="_blank" href={viewShedRequestUrl}>deze link</a> kun je geconfigureerde DUBLO bekijken en delen met anderen.</Typography>
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button autoFocus color="primary" variant="contained" onClick={closeShedRequestIsSentDialogHandler}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={props.show}
            onClose={props.onClose}
            fullWidth
            maxWidth="md"
        >
            <FormContainer
                onSuccess={handleOrderButtonClick}
            >
                <DialogTitle variant="h2">
                    Offerte aanvragen
                    <IconButton
                        onClick={props.onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Tevreden met je DUBLO?<br />
                        Vul onderstaand je gegevens in en binnen één werkdag ontvang je een reactie van ons.
                    </Typography>

                    <Box component="div" marginTop={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextFieldElement
                                    value={userInput.nameOrCompanyName}
                                    label="Naam of bedrijfsnaam"
                                    fullWidth
                                    name="nameOrCompanyName"
                                    onChange={handleInputChange}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextFieldElement
                                    value={userInput.contactPerson}
                                    label="Contactpersoon"
                                    fullWidth
                                    name="contactPerson"
                                    onChange={handleInputChange}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextFieldElement
                                    value={userInput.email}
                                    validation={{
                                        required: 'Is verplicht',
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: 'Is geen gelding e-mailadres'
                                        }
                                    }}
                                    label="E-mailadres"
                                    required
                                    fullWidth
                                    name="email"
                                    type="email"
                                    onChange={handleInputChange}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextFieldElement
                                    value={userInput.telephone}
                                    validation={{
                                        required: 'Is verplicht'
                                    }}
                                    label="Telefoonnummer"
                                    required
                                    fullWidth
                                    name="telephone"
                                    type="tel"
                                    onChange={handleInputChange}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextFieldElement
                                    value={userInput.streetAndHouseNumber}
                                    label="Straat en huisnummer"
                                    fullWidth
                                    name="streetAndHouseNumber"
                                    onChange={handleInputChange}
                                />
                            </Grid>

                            <Grid item xs={4} sm={2}>
                                <TextFieldElement
                                    value={userInput.zip}
                                    label="Postcode"
                                    fullWidth
                                    name="zip"
                                    onChange={handleInputChange}
                                />
                            </Grid>

                            <Grid item xs={8} sm={4}>
                                    <TextFieldElement
                                        value={userInput.city}
                                        validation={{
                                            required: 'Is verplicht'
                                        }}
                                        label="Woonplaats"
                                        required
                                        fullWidth
                                        name="city"
                                        onChange={handleInputChange}
                                    />
                            </Grid>

                            <Grid item xs={12}>
                                <TextFieldElement
                                    value={userInput.notes}
                                    label="Opmerkingen"
                                    fullWidth
                                    multiline
                                    minRows={3}
                                    name="notes"
                                    onChange={handleInputChange}
                                />
                            </Grid>

                        </Grid>
                    </Box>

                </DialogContent>

                <DialogActions>
                    <Button color="inherit" variant="text" onClick={props.onClose}>
                        Annuleren
                    </Button>
                    <LoadingButton
                        type="submit"
                        loading={formIsSubmitting}
                        variant="contained"
                        color="primary"
                    >
                        Offerte aanvragen
                    </LoadingButton>
                </DialogActions>
            </FormContainer>
        </Dialog>

        <Dialog
            open={formErrorMessage !== null}
            onClose={closeFormErrorDialogHandler}
        >
            <DialogContent>
                <Typography color="error">{formErrorMessage}</Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus color="primary" variant="contained" onClick={closeFormErrorDialogHandler}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default OrderShedPopup
