import { FC, useEffect, useMemo } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {
    EquirectangularReflectionMapping,
    Material,
    Mesh,
    MeshPhongMaterial,
    MeshPhysicalMaterial,
    Vector3
} from 'three'
import { useDevToolsContext } from '../../../../dev-tools/context'
import { WallFeature, WallFeatures } from '../../../../wall-features'
import mooColor from 'moo-color'
import DimensionVisualizer from '../../../dimension-visualizer/dimension'
import { ChildPropsInterface } from '../interactive-feature-wrapper/child-props'

interface Props extends ChildPropsInterface {
    detailingMaterial: Material
}

type GLTFResult = GLTF & {
    nodes: {
        glass: Mesh,
        frame: Mesh,
        door: Mesh
    }
}

const DoubleDoorWithoutSidePanels:FC<Props> = (props) => {
    const { nodes } = useGLTF(`${import.meta.env.VITE_BASE_URL}/static/3d/double-door-without-side-panels.glb`) as GLTFResult
    const { options: devToolsOptions } = useDevToolsContext()

    const wallFeature = useMemo(() => {
        return WallFeatures.find(feature => feature.key === 'doubleDoorWithSidePanels') as WallFeature
    }, [])

    const materials = useMemo(() => {
        const glass = new MeshPhysicalMaterial({
            metalness: 0,
            roughness: 0,
            transmission: 1,
            // reflectivity: 1,
            wireframe: devToolsOptions.showWireframes,
            // @ts-ignore
            thickness: 0,
            // envMap: glassEnvMap,
            envMapIntensity: 0.5,
            ior: 2,
            color: '#c9c9c9'
        })

        const aluminium = props.detailingMaterial.clone() as MeshPhongMaterial
        aluminium.wireframe = devToolsOptions.showWireframes

        return { glass, aluminium }
    }, [devToolsOptions.showWireframes, props.detailingMaterial])

    useEffect(() => {
        Object.entries(materials).forEach(([key, material]) => {
            material.userData = {...material.userData, defaultColor: '#' + material.color.getHexString()}
        })
    }, [props.detailingMaterial])

    useEffect(() => {
        Object.entries(materials).forEach(([key, material]) => {
            if (props.isHovered || props.isCollidingWithOtherObjects) {
                let theColor = new mooColor(material.userData.defaultColor)

                if (props.isHovered) {
                    theColor.blacken(20)
                }

                if (props.isCollidingWithOtherObjects) {
                    theColor = theColor.mix(new mooColor('#ff0000'))
                }

                material.color.set(theColor.toHex())
            } else {
                material.color.set(material.userData.defaultColor)
            }
        })
    }, [props.isHovered, props.isCollidingWithOtherObjects, materials])

    return <>
        <group>
            {props.showDimensions &&
                <group>
                    <DimensionVisualizer
                        name="Width"
                        pointA={new Vector3(0, wallFeature.dimensions.heightMeter, 0)}
                        pointB={new Vector3(wallFeature.dimensions.widthMeter, wallFeature.dimensions.heightMeter, 0)}
                        helperLinesDirection={new Vector3(0, 0, 1)}
                        helperLinesLength={0.5}
                        textAnchorOffsetY={0.05}
                        text={wallFeature.dimensions.widthMeter.toLocaleString('nl', {
                            maximumFractionDigits: 2
                        }) + ' m'}
                    />
                    <DimensionVisualizer
                        name="Height"
                        pointA={new Vector3(0, 0, 0)}
                        pointB={new Vector3(0, wallFeature.dimensions.heightMeter, 0)}
                        helperLinesDirection={new Vector3(0, 0, 1)}
                        helperLinesLength={0.5}
                        textAnchorPositionX="right"
                        textAnchorOffsetX={-0.05}
                        text={wallFeature.dimensions.heightMeter.toLocaleString('nl', {
                            maximumFractionDigits: 2
                        }) + ' m'}
                    />
                </group>
            }

            <group position={[wallFeature.feature3DObject.offset.x,
                wallFeature.feature3DObject.offset.y,
                wallFeature.feature3DObject.offset.z
            ]}>
                <mesh geometry={nodes.glass.geometry} material={materials.glass} />
                <mesh castShadow geometry={nodes.frame.geometry} material={materials.aluminium} />
                <mesh castShadow geometry={nodes.door.geometry} material={materials.aluminium} />
            </group>
        </group>
    </>
}

export default DoubleDoorWithoutSidePanels

