import { BaseSyntheticEvent, FC, useCallback, useMemo } from 'react'
import { ConfigurationInterface, SetConfigurationInterface } from '../../configuration'
import shedTypes, { ShedType, shedTypeKeys } from '../../shed-types'
import { Box, Divider, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { cloneDeep } from 'lodash'
import {
    defaultConfiguration,
    defaultConfigurationInformalCareHome,
    defaultShedTypeFeatures
} from '../../default-configurations'
import { WallFeatures } from '../../wall-features'

interface Props {
    configuration: ConfigurationInterface,
    setConfiguration: SetConfigurationInterface
    showPrices: boolean
}

const ShedTypeSection:FC<Props> = ({ configuration, setConfiguration, showPrices }) => {
    const setShedType = (newType: typeof shedTypeKeys[number]) => {
        const newShedType = shedTypes.find(type => type.key === newType) as ShedType

        let newConfiguration:ConfigurationInterface
        if (newShedType.isInformalCareHome) {
            newConfiguration = cloneDeep(defaultConfigurationInformalCareHome)
        } else {
            newConfiguration = cloneDeep(defaultConfiguration)
        }
        newConfiguration.shedType = newType

        const defaultFeatures = defaultShedTypeFeatures.find(obj => obj.shedType === newConfiguration.shedType)
        if (defaultFeatures !== undefined) {
            newConfiguration.features = defaultFeatures.features
        }

        setConfiguration(newConfiguration)
    }

    const changeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setShedType(event.target.value as typeof shedTypeKeys[number])
    }, [])

    const clickHandler = useCallback((event: BaseSyntheticEvent) => {
        setShedType(event.target.getAttribute('data-shed-type-key'))
    }, [])

    const shedTypesNormal = useMemo(() => {
        return shedTypes.filter(shedType => !shedType.isInformalCareHome).map((shedType, index) => {
            let priceIncludingDefaultFeatures = shedType.basePriceEuro
            const defaultFeatures = defaultShedTypeFeatures.find(obj => obj.shedType === shedType.key)
            if (defaultFeatures !== undefined) {
                defaultFeatures.features.forEach(defaultFeature => {
                    const feature = WallFeatures.find(wallFeature => wallFeature.key === defaultFeature.featureKey)
                    if (feature !== undefined) {
                        priceIncludingDefaultFeatures += feature.priceEuro
                    }
                })
            }

            const priceIncludingDefaultFeaturesIncVat = 1.21 * priceIncludingDefaultFeatures

            return <Grid
                key={index}
                item
                xs={6}
            >
                {shedType.optionImageFilename !== undefined &&
                    <Box
                        data-shed-type-key={shedType.key}
                        component="div"
                        onClick={clickHandler}
                        sx={{
                            textAlign: 'center',
                            backgroundColor: '#eee',
                            padding: '10px 0'
                        }}
                    >
                        <img
                            src={`${import.meta.env.VITE_DUBLO_APP_FILES_URL}/static/option-images/${shedType.optionImageFilename}`}
                            alt={shedType.name}
                            data-shed-type-key={shedType.key}
                            style={{
                                width: '200px',
                                maxWidth: '100%',
                                display: 'inline-block'
                            }}
                        />
                    </Box>
                }
                {shedType.optionImageFilename === undefined &&
                    <Box
                        data-shed-type-key={shedType.key}
                        component="div"
                        sx={{
                            backgroundColor: '#eee',
                            paddingTop: '100%'
                        }}
                        onClick={clickHandler}
                    >
                    </Box>
                }
                <Box
                    component="div"
                    sx={{
                        textAlign: 'center'
                    }}
                >
                    <FormControlLabel
                        value={shedType.key}
                        labelPlacement="bottom"
                        control={<Radio />}
                        label={<Typography variant="subtitle2"><strong>{shedType.name}</strong>: {shedType.dimensions}</Typography>}
                        checked={shedType.key === configuration.shedType}
                        sx={{
                            margin: 0
                        }}
                    />
                </Box>
                {showPrices &&
                    <Box component="div" marginTop={0.6}>
                        <Typography variant="subtitle2" textAlign="center" color="primary">
                            &euro; {priceIncludingDefaultFeatures.toLocaleString('nl-NL', {maximumFractionDigits: 0})},-
                            excl. BTW
                        </Typography>
                        <Typography variant="subtitle2" sx={{
                            color: '#666666',
                            fontSize: '9px',
                            textAlign: 'center'
                        }}>
                            &euro; {priceIncludingDefaultFeaturesIncVat.toLocaleString('nl-NL', {maximumFractionDigits: 0})},-
                            incl. BTW
                        </Typography>
                    </Box>
                }
            </Grid>
        })
    }, [configuration.shedType])

    const shedTypesInformalCare = useMemo(() => {
        return shedTypes.filter(shedType => shedType.isInformalCareHome).map((shedType, index) => {
            let priceIncludingDefaultFeatures = shedType.basePriceEuro
            const defaultFeatures = defaultShedTypeFeatures.find(obj => obj.shedType === shedType.key)
            if (defaultFeatures !== undefined) {
                defaultFeatures.features.forEach(defaultFeature => {
                    const feature = WallFeatures.find(wallFeature => wallFeature.key === defaultFeature.featureKey)
                    if (feature !== undefined) {
                        priceIncludingDefaultFeatures += feature.priceEuro
                    }
                })
            }

            const priceIncludingDefaultFeaturesIncVat = 1.21 * priceIncludingDefaultFeatures

            return <Grid
                key={index}
                item
                xs={6}
            >
                {shedType.optionImageFilename !== undefined &&
                    <Box
                        data-shed-type-key={shedType.key}
                        component="div"
                        onClick={clickHandler}
                        sx={{
                            textAlign: 'center',
                            backgroundColor: '#eee',
                            padding: '10px 0'
                        }}
                    >
                        <img
                            src={`${import.meta.env.VITE_DUBLO_APP_FILES_URL}/static/option-images/${shedType.optionImageFilename}`}
                            alt={shedType.name}
                            data-shed-type-key={shedType.key}
                            style={{
                                width: '200px',
                                maxWidth: '100%',
                                display: 'inline-block'
                            }}
                        />
                    </Box>
                }
                {shedType.optionImageFilename === undefined &&
                    <Box
                        data-shed-type-key={shedType.key}
                        component="div"
                        sx={{
                            backgroundColor: '#eee',
                            paddingTop: '100%'
                        }}
                        onClick={clickHandler}
                    >
                    </Box>
                }
                <Box
                    component="div"
                    sx={{
                        textAlign: 'center'
                    }}
                >
                    <FormControlLabel
                        value={shedType.key}
                        labelPlacement="bottom"
                        control={<Radio />}
                        label={<Typography variant="subtitle2"><strong>{shedType.name}</strong>: {shedType.dimensions}</Typography>}
                        checked={shedType.key === configuration.shedType}
                        sx={{
                            margin: 0
                        }}
                    />
                </Box>
                {showPrices &&
                    <Box component="div" marginTop={0.6}>
                        <Typography variant="subtitle2" textAlign="center" color="primary">
                            &euro; {priceIncludingDefaultFeatures.toLocaleString('nl-NL', {maximumFractionDigits: 0})},-
                            excl. BTW
                        </Typography>
                        <Typography variant="subtitle2" sx={{
                            color: '#666666',
                            fontSize: '9px',
                            textAlign: 'center'
                        }}>
                            &euro; {priceIncludingDefaultFeaturesIncVat.toLocaleString('nl-NL', {maximumFractionDigits: 0})},-
                            incl. BTW
                        </Typography>
                    </Box>
                }
            </Grid>
        })
    }, [configuration.shedType])

    return <>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={changeHandler}
            >
                <Grid
                    container
                    spacing={2}
                >
                    {shedTypesNormal}
            </Grid>
        </RadioGroup>
        <Divider sx={{
            marginTop: 3,
            marginBottom: 2
        }} />
        <Typography variant="h3" gutterBottom>Mantelzorg woningen</Typography>
        <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={changeHandler}
        >
            <Grid
                container
                spacing={2}
            >
                {shedTypesInformalCare}
            </Grid>
        </RadioGroup>
    </>
}

export default ShedTypeSection
