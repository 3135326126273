import { ChangeEvent, FC } from 'react'
import { ConfigurationInterface, SetConfigurationInterface } from '../../../configuration'
import { cloneDeep } from 'lodash'
import colorsAndFinishes, { keys } from '../../../colors-and-finishes'
import { Box, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import Image from 'material-ui-image'

interface Props {
    configuration: ConfigurationInterface,
    setConfiguration: SetConfigurationInterface
}

const ConcretePlinth:FC<Props> = (props) => {
    const setConcretePlinthColorOrFinish = (key: typeof keys.concretePlinth[number]) => {
        const newConfiguration = cloneDeep(props.configuration)
        newConfiguration.colorsAndFinish.concretePlinth = key
        props.setConfiguration(newConfiguration)
    }

    const concretePlinthChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setConcretePlinthColorOrFinish(event.target.value as typeof keys.concretePlinth[number])
    }

    return <>
        <Typography>
            <strong>Betonplint</strong>
        </Typography>

        <RadioGroup onChange={concretePlinthChangeHandler}>
            <Grid container spacing={1} sx={{
                marginTop: 1
            }}>
                {colorsAndFinishes.concretePlinth.map((colorOrFinish, index) => {
                    return <Grid key={index} item xs={4}>
                        {colorOrFinish.exampleImgPath !== undefined && <>
                            <Image
                                src={`${import.meta.env.VITE_BASE_URL}${colorOrFinish.exampleImgPath}`}
                                aspectRatio={3/2}
                                onClick={() => {
                                    setConcretePlinthColorOrFinish(colorOrFinish.key)
                                }}
                            />
                        </>}

                        {colorOrFinish.exampleImgPath === undefined && <>
                            <Box
                                component="div"
                                sx={{
                                    backgroundColor: colorOrFinish.exampleHexColor,
                                    paddingTop: '66.6%'
                                }}
                                onClick={() => {
                                    setConcretePlinthColorOrFinish(colorOrFinish.key)
                                }}
                            >
                            </Box>
                        </>}

                        <Box
                            component="div"
                            sx={{
                                textAlign: 'center'
                            }}
                        >
                            <FormControlLabel
                                value={colorOrFinish.key}
                                labelPlacement="bottom"
                                control={<Radio />}
                                label={<Typography variant="subtitle2">{colorOrFinish.name}</Typography>}
                                checked={colorOrFinish.key === props.configuration.colorsAndFinish.concretePlinth}
                                sx={{
                                    margin: 0
                                }}
                            />
                        </Box>
                    </Grid>
                })}
            </Grid>
        </RadioGroup>
    </>
}

export default ConcretePlinth
