import { FC, useCallback, useEffect, useRef } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { Box3, Mesh } from 'three'
import { OBB } from 'three/examples/jsm/math/OBB'
import { useCollidingMeshesContext } from './context'
import { isEqual, throttle } from 'lodash'
import { CollisionMeshUserData } from './mesh-user-data'
import { ConfigurationInterface } from '../../configuration'

interface Props {
    configuration: ConfigurationInterface
}

const CollidingMeshesDetector:FC<Props> = (props) => {
    const { collidingMeshesUuids, setCollidingMeshesUuids } = useCollidingMeshesContext()
    const { scene } = useThree()
    const firstRenderHitTestDoneRef = useRef(false)

    const collidingMeshesUuidsRef = useRef(collidingMeshesUuids)
    const setCollidingMeshesUuidsRef = useRef(setCollidingMeshesUuids)

    useEffect(() => {
        collidingMeshesUuidsRef.current = collidingMeshesUuids
    }, [collidingMeshesUuids])

    useEffect(() => {
        setCollidingMeshesUuidsRef.current = setCollidingMeshesUuids
    }, [setCollidingMeshesUuids])

    const throttledFunc = useCallback(throttle(() => {
        const collidableMeshes:Mesh[] = []

        scene.traverse((child) => {
            if (child.userData?.isCollisionMesh === true) {
                collidableMeshes.push(child as Mesh)
            }
        })

        const meshCombinationsTested: string[] = []
        const collidingUuids: string[] = []

        for (const mesh1 of collidableMeshes) {
            const mesh1UserData = mesh1.userData as CollisionMeshUserData
            for (const mesh2 of collidableMeshes) {
                const mesh2UserData = mesh2.userData as CollisionMeshUserData

                if (mesh1 !== mesh2
                    && mesh1UserData.configurationShedWallFeatureUuid !== mesh2UserData.configurationShedWallFeatureUuid
                    && !meshCombinationsTested.includes(mesh1.uuid + ',' + mesh2.uuid)
                    && !meshCombinationsTested.includes(mesh2.uuid + ',' + mesh1.uuid)
                ) {
                    if (mesh1UserData.obb === undefined) {
                        mesh1.geometry.computeBoundingBox()
                        mesh1UserData.obb = new OBB().fromBox3(
                            mesh1.geometry.boundingBox as Box3
                        )
                    }

                    if (mesh2UserData.obb === undefined) {
                        mesh2.geometry.computeBoundingBox()
                        mesh2UserData.obb = new OBB().fromBox3(
                            mesh2.geometry.boundingBox as Box3
                        )
                    }

                    const mesh1Obb = new OBB().copy(mesh1UserData.obb)
                    const mesh2Obb = new OBB().copy(mesh2UserData.obb)

                    mesh1Obb.applyMatrix4(mesh1.matrixWorld)
                    mesh2Obb.applyMatrix4(mesh2.matrixWorld)

                    if (mesh1Obb.intersectsOBB(mesh2Obb)) {
                        if (mesh1UserData?.configurationShedWallFeatureUuid !== undefined) {
                            collidingUuids.push(mesh1UserData.configurationShedWallFeatureUuid)
                        }
                        if (mesh2UserData?.configurationShedWallFeatureUuid !== undefined) {
                            collidingUuids.push(mesh2UserData.configurationShedWallFeatureUuid)
                        }
                    }

                    meshCombinationsTested.push(mesh1.uuid + ',' + mesh2.uuid)
                }
            }
        }

        if (collidingUuids.length > 0) {
            if (!isEqual(collidingUuids, collidingMeshesUuidsRef.current)) {
                setCollidingMeshesUuidsRef.current(collidingUuids)
            }
        } else if (collidingUuids.length === 0 && collidingMeshesUuidsRef.current.length > 0) {
            setCollidingMeshesUuidsRef.current([])
        }
    }, 50), [])

    // somehow needed because else everything is hitted on load for some reason
    // better to improve this some day though
    useFrame(() => {
        if (!firstRenderHitTestDoneRef.current) {
            firstRenderHitTestDoneRef.current = true
            window.setTimeout(() => {
                throttledFunc()
                throttledFunc()
                //console.log('first render hit test')
            }, 200)
        }
    })

    useEffect(() => {
        window.setTimeout(throttledFunc, 50) // without the timeout everything collides according to the detector :/
    }, [props.configuration, throttledFunc])

    return <></>
}

export default CollidingMeshesDetector
