import { FC, ReactNode, useEffect, useRef } from 'react'
import { useHitTest } from '@react-three/xr'
import { Group, Matrix4, XRHitTestResult } from 'three'
import { degToRad } from 'three/src/math/MathUtils'

interface Props {
    children: ReactNode|ReactNode[]
    arModeEnabled: boolean
    arCurrentMode: 'place-marker'|'viewing'
    arObjectScale: number
    arObjectRotation: number
    shedLengthMeter: number
    hitTestCallback: () => void
}

const ArGroup:FC<Props> = (props) => {
    const hitTestBoxRef = useRef<Group>(null)

    useHitTest((hitMatrix: Matrix4, hit: XRHitTestResult) => {
        props.hitTestCallback()
        if (props.arModeEnabled && props.arCurrentMode === 'place-marker' && hitTestBoxRef.current !== null) {
            hitMatrix.decompose(hitTestBoxRef.current.position, hitTestBoxRef.current.quaternion, hitTestBoxRef.current.scale)
        }
    })

    return <group>
        {props.arModeEnabled &&
            <group ref={hitTestBoxRef}>

                    <group
                        scale={[props.arObjectScale / 100, props.arObjectScale / 100, props.arObjectScale / 100]}
                    >
                        <group
                            position={[0, 0, -(props.shedLengthMeter / 2)]}
                            rotation={[0, degToRad(props.arObjectRotation), 0]}
                        >
                            {props.children}
                        </group>
                    </group>
            </group>
        }
        {!props.arModeEnabled && props.children }
    </group>
}

export default ArGroup
