import { FC, useMemo } from 'react'
import { ShedWallFeatureInterface } from '../../../../configuration'
import renderSettings from '../../../settings'
import { BoxGeometry, ExtrudeBufferGeometry, MathUtils, Mesh, Shape } from 'three'
import { WallFeature, WallFeatures } from '../../../../wall-features'
import { CSG } from 'three-csg-ts'

interface Props {
    side: 'front' | 'back' | 'left' | 'right'
    widthMeter: number
    ridgeHeightMeter: number
    eaveHeightMeter: number
    features: ShedWallFeatureInterface[]
    roofCornerDegrees: number
}

const WallPaint:FC<Props> = (props) => {

    const { geometry, position } = useMemo(() => {
        const wallWidthMeter = props.widthMeter - (renderSettings.concretePlinth.thicknessMeter * 2)

        const wallShape = new Shape()
        wallShape.autoClose = true
        if (props.side === 'front' || props.side === 'back') {
            const delta = Math.tan(MathUtils.degToRad(props.roofCornerDegrees)) * renderSettings.concretePlinth.thicknessMeter

            wallShape.moveTo(0, 0)
                .lineTo(wallWidthMeter, 0)
                .lineTo(wallWidthMeter, props.eaveHeightMeter + delta)
                .lineTo(wallWidthMeter /2, props.ridgeHeightMeter)
                .lineTo(0, props.eaveHeightMeter + delta)
        } else {
            const delta = Math.tan(MathUtils.degToRad(props.roofCornerDegrees)) * (renderSettings.concretePlinth.thicknessMeter)

            wallShape.moveTo(0, 0)
                .lineTo(wallWidthMeter, 0)
                .lineTo(wallWidthMeter, props.eaveHeightMeter + delta)
                .lineTo(0, props.eaveHeightMeter + delta)
        }

        const extrudeSettings = {
            depth: 0.1,
            bevelEnabled: false
        }

        let wallGeometry = new ExtrudeBufferGeometry(wallShape, extrudeSettings)
        let wallMesh = new Mesh(wallGeometry) as Mesh
        wallMesh.position.set(renderSettings.concretePlinth.thicknessMeter, 0, -renderSettings.concretePlinth.thicknessMeter - 0.1)
        wallMesh.updateMatrix()

        for (const configurationFeature of props.features) {
            const wallFeature = WallFeatures.find(wallFeature => wallFeature.key === configurationFeature.featureKey) as WallFeature

            if (wallFeature.cutHoleInWall) {
                const slicerBoxGeometry = new BoxGeometry(wallFeature.holeInWall.widthMeter, wallFeature.holeInWall.heightMeter, 2)
                const slicerMesh = new Mesh(slicerBoxGeometry)
                slicerMesh.position.x = configurationFeature.distanceLeftMeter + wallFeature.holeInWall.offset.x + (wallFeature.holeInWall.widthMeter / 2)
                slicerMesh.position.y = wallFeature.distanceFromBottomMeter + wallFeature.holeInWall.offset.y + (wallFeature.holeInWall.heightMeter / 2)
                slicerMesh.updateMatrix()
                wallMesh = CSG.subtract(wallMesh, slicerMesh) as Mesh
            }
        }

        return { geometry: wallMesh.geometry, position: wallMesh.position }
    }, [props.roofCornerDegrees, props.ridgeHeightMeter, props.widthMeter, props.features, props.side, props.eaveHeightMeter])

    return <>
        <group>
            <mesh
                castShadow
                geometry={geometry}
                position={position}
                onPointerOver={(event) => {
                    event.stopPropagation()
                }}
            >
                <meshPhongMaterial
                    color={'#d0cdc7'}
                />
            </mesh>
        </group>
    </>
}

export default WallPaint
