import { FC, useEffect, useState } from 'react'
import Renderer from './renderer'
import { Box, Button, Slider, Typography } from '@mui/material'
import ControlPanel from './control-panel'
import DevToolsContextProvider from './dev-tools/context'
import DevToolsControlPanel from './dev-tools/control-panel'
import InteractableObjectContextProvider, { generateInteractableObjectUuid } from './interactable-object/context'
import OrbitControlsContextProvider from './renderer/orbit-controls/context'
import Price from './price'
import OrderShedPopup from './order-shed-popup'
import Toolbar from './toolbar'
import SelectedObjectMenu from './selected-object/menu'
import useUndoableState from './hooks/history'
import { saveAs } from 'file-saver'
import { SelectedObjectMeshTopRight2DPositionContextProvider } from './selected-object/mesh-top-right-2d-position/context'
import shedTypes, { ShedType, shedTypeKeys } from './shed-types'
import {
    defaultConfiguration,
    defaultConfigurationInformalCareHome,
    defaultShedTypeFeatures
} from './default-configurations'
import ByLucusDotNl from './by-lucus-dot-nl'
import { XRButton } from '@react-three/xr'
import ViewInArIcon from '@mui/icons-material/ViewInAr'
import sides from './renderer/shed/sides'
import ArControls from './ar-controls'
import LoadingOverlay from './loading-overlay'
import BrowserDoesNotSupportArDialog from './browser-does-not-support-ar-dialog'
import { cloneDeep } from 'lodash'
import { ConfigurationInterface } from './configuration'

export interface Props {
    selectedShedTypeKey?: typeof shedTypeKeys[number]
    thankYouPageUrl: string|null
    iframeParentUrl: string|null
}

const showPrices = false

const Configurator:FC<Props> = (props) => {
    const [showOrderShedPopup, setShowOrderShedPopup] = useState(false)
    const [showDimensions, setShowDimensions] = useState(false)
    const [renderScreenshotBlob, setRenderScreenshotBlob] = useState<null|Blob>(null)
    const [makeScreenshotForRequestShedPopup, setMakeScreenshotForRequestShedPopup] = useState(false)
    const [makeScreenshotForDownload, setMakeScreenshotForDownload] = useState(false)
    const [arModeEnabled, setArModeEnabled] = useState(false)
    const [browserSupportsAr, setBrowserSupportsAr] = useState(false)
    const [arCurrentMode, setArCurrentMode] = useState<'place-marker'|'viewing'>('place-marker')
    const [arObjectScale, setArObjectScale] = useState(5)
    const [arObjectRotationDegrees, setArObjectRotationDegrees] = useState(0)
    const [cameraIsFacingSide, setCameraIsFacingSide] = useState<typeof sides[number]>('front')
    const [forceShowLoadingOverlay, setForceShowLoadingOverlay] = useState(true)
    const [doInitialAnimation, setDoInitialAnimation] = useState(false)
    const [showBrowserDoesNotSupportArDialog, setShowBrowserDoesNotSupportArDialog] = useState(false)
    const [objectIsPlaceableInAR, setObjectIsPlaceableInAR] = useState(false)

    const loadingOverlayStartedLoadingSomethingCallbackHandler = () => {
        setForceShowLoadingOverlay(false)
    }

    const rendererReadyHandler = () => {
        setDoInitialAnimation(true)
    }

    const closeBrowserDoesNotSupportArDialogHandler = () => {
        setShowBrowserDoesNotSupportArDialog(false)
    }

    useEffect(() => {
        (async() => {
            try {
                // @ts-ignore
                if (navigator?.xr !== undefined) {
                    // @ts-ignore
                    const result = await navigator.xr.isSessionSupported('immersive-ar')
                    if (result === true) {
                        setBrowserSupportsAr(true)
                    }
                }
            } catch (error) {
                // https://www.youtube.com/watch?v=u9Dg-g7t2l4
            }
        })()
    }, [])

    useEffect(() => {
        (async() => {
            const searchParams = new URLSearchParams(window.location.search)
            const shedRequestUuid = searchParams.get('shed-request-uuid')
            if (shedRequestUuid !== null) {
                try {
                    const response = await fetch(`${import.meta.env.VITE_DUBLO_PUBLIC_API_URL}/items/shed_requests/${shedRequestUuid}`, {
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        method: 'get'
                    });
                    const json = await response.json()
                    setConfiguration(json.data.configuration_json)
                } catch (error) {
                    // fail silently, maybe better to show an error somewhere?
                    console.error(error)
                }
            } else {
                let newConfiguration:ConfigurationInterface

                if (props.selectedShedTypeKey !== undefined) {
                    const newShedType = shedTypes.find(type => type.key === props.selectedShedTypeKey) as ShedType
                    if (newShedType.isInformalCareHome) {
                        newConfiguration = cloneDeep(defaultConfigurationInformalCareHome)
                    } else {
                        newConfiguration = cloneDeep(defaultConfiguration)
                    }
                    newConfiguration.shedType = newShedType.key
                } else {
                    newConfiguration = cloneDeep(defaultConfiguration)
                }

                const defaultFeatures = defaultShedTypeFeatures.find(obj => obj.shedType === newConfiguration.shedType)
                if (defaultFeatures !== undefined) {
                    newConfiguration.features = defaultFeatures.features
                }
                setConfiguration(newConfiguration) // not too sure about this, but seems needed
            }
        })()
    }, [])

    const {
        state: configuration,
        setState: setConfiguration,
        canGoBack: canUndo,
        goBack: undo,
        canGoForward: canRedo,
        goForward: redo
    } = useUndoableState(defaultConfiguration)

    const onBuyShedButtonClick = () => {
        setMakeScreenshotForRequestShedPopup(true)
    }

    const closeOrderShedPopupHandler = () => {
        setShowOrderShedPopup(false)
    }

    const screenshotMadeCallback = (blob: Blob) => {
        if (makeScreenshotForDownload) {
            saveAs(blob, 'dublo-schuur.png')
        } else if (makeScreenshotForRequestShedPopup) {
            setRenderScreenshotBlob(blob)
            setShowOrderShedPopup(true)
        }
        setMakeScreenshotForDownload(false)
        setMakeScreenshotForRequestShedPopup(false)
    }

    const onMakeScreenshotClick = () => {
        setMakeScreenshotForDownload(true)
    }

    const arSessionsStartHandler = () => {
        setArModeEnabled(true)
    }

    const arSessionsEndHandler = () => {
        setArModeEnabled(false)
    }

    const arCurrentModeButtonClickHandler = () => {
        setArCurrentMode(arCurrentMode === 'viewing' ? 'place-marker' : 'viewing')
    }

    const cameraFacingSideChangeHandler = (newSide: typeof sides[number]) => {
        setCameraIsFacingSide(newSide)
    }

    const viewInArButtonClickHandler = () => {
        setObjectIsPlaceableInAR(false)
        setArCurrentMode('place-marker')
        if (!browserSupportsAr) {
            setShowBrowserDoesNotSupportArDialog(true)
        }
    }

    const hitTestCallback = () => {
        setObjectIsPlaceableInAR(true)
    }

    return <>
        <DevToolsContextProvider>
            <SelectedObjectMeshTopRight2DPositionContextProvider>
                <InteractableObjectContextProvider>
                    <OrbitControlsContextProvider>
                        <Box component="div" sx={{
                            position: 'absolute',
                            width: '100vw',
                            height: '100%',
                            overscrollBehavior: 'none',
                            overflow: 'hidden'
                        }}
                        >
                            <LoadingOverlay
                                forceShowOverlay={forceShowLoadingOverlay}
                                startedLoadingSomethingOneTimeCallback={loadingOverlayStartedLoadingSomethingCallbackHandler}
                            />
                            <BrowserDoesNotSupportArDialog
                                show={showBrowserDoesNotSupportArDialog}
                                closeHandler={closeBrowserDoesNotSupportArDialogHandler}
                            />
                            {(import.meta.env.DEV) &&
                                <Box component="div" sx={{
                                    position: 'absolute',
                                    bottom: 20,
                                    right: 20,
                                    zIndex: 10000
                                }}>
                                    <DevToolsControlPanel/>
                                </Box>
                            }
                            {arModeEnabled && <>
                                <Box component="div" sx={{
                                    position: 'absolute',
                                    top: 50,
                                    right: 50,
                                    zIndex: 10000
                                }}>
                                    <XRButton
                                        mode="AR"
                                        sessionInit={{
                                            requiredFeatures: ['hit-test'],
                                            optionalFeatures: ['dom-overlay', 'dom-overlay-for-handheld-ar'],
                                            domOverlay: { root: document.body }
                                        }}
                                        style={{
                                            border: 'none',
                                            lineHeight: 1,
                                            margin: 0,
                                            padding: '3px 7px',
                                            fontSize: '10px',
                                            opacity: 0.7
                                        }}
                                    >
                                        <ViewInArIcon sx={{ verticalAlign: 'middle', marginRight: 1 }} />
                                        3D sluiten
                                    </XRButton>
                                </Box>

                                {arCurrentMode === 'place-marker' && <>
                                    <Box component="div" sx={{
                                        position: 'absolute',
                                        top: 100,
                                        left: 30,
                                        right: 30,
                                        zIndex: 10000,
                                        padding: '10px',
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)'
                                    }}>
                                        <Typography>Scan de omgeving met je camera, en klik op "Plaats DUBLO".</Typography>
                                        <Typography>De DUBLO is te plaatsen op vlakke ondergronden, dus bijvoorbeeld een vloer of een tafel.</Typography>
                                    </Box>
                                </>}

                                {arCurrentMode === 'viewing' && <>
                                    <Box component="div" sx={{
                                        position: 'absolute',
                                        bottom: 40,
                                        left: 30,
                                        right: 30,
                                        zIndex: 10000,
                                        padding: '10px 20px',
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)'
                                    }}>
                                        <ArControls
                                            arObjectScale={arObjectScale}
                                            setArObjectScale={setArObjectScale}
                                            arObjectRotationDegrees={arObjectRotationDegrees}
                                            setArObjectRotationDegrees={setArObjectRotationDegrees}
                                        />
                                    </Box>
                                </>}
                                {(objectIsPlaceableInAR || arCurrentMode === 'viewing') &&
                                    <Box component="div" sx={{
                                        position: 'absolute',
                                        bottom: 250,
                                        left: 0,
                                        right: 0,
                                        zIndex: 10000,
                                        textAlign: 'center',
                                        pointerEvents: 'none'
                                    }}>
                                        <Button
                                            sx={{pointerEvents: 'all'}}
                                            onClick={arCurrentModeButtonClickHandler}
                                            variant="contained"
                                        >
                                            {arCurrentMode === 'viewing' ? 'Herplaats DUBLO' : 'Plaats DUBLO'}
                                        </Button>
                                    </Box>
                                }
                            </>}
                            {!arModeEnabled &&
                                <>
                                    <SelectedObjectMenu
                                        configuration={configuration}
                                        setConfiguration={setConfiguration}
                                    />
                                    <OrderShedPopup
                                        show={showOrderShedPopup}
                                        onClose={closeOrderShedPopupHandler}
                                        configuration={configuration}
                                        renderScreenshotBlob={renderScreenshotBlob as Blob}
                                        thankYouPageUrl={props.thankYouPageUrl}
                                        iframeParentUrl={props.iframeParentUrl}
                                    />
                                    <Box component="div" sx={{
                                        position: 'absolute',
                                        right: 0,
                                        top: 0,
                                        height: '100%',
                                        width: '350px',
                                        backgroundColor: '#fff',
                                        ['@media (max-width:800px)']: {
                                            width: 'auto',
                                            top: 'auto',
                                            left: 0,
                                            bottom: 0,
                                            height: '60%'
                                        }
                                    }}
                                    >
                                        <Box component="div" sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0
                                        }}>
                                            <Box component="div" sx={{
                                                flexGrow: 1,
                                                overflow: 'auto',
                                                width: '100%',
                                                padding: 1,
                                                ['@media (max-width:800px)']: {
                                                    paddingTop: 0,
                                                    order: '20'
                                                }
                                            }}>
                                                <ControlPanel
                                                    configuration={configuration}
                                                    setConfiguration={setConfiguration}
                                                    cameraIsFacingSide={cameraIsFacingSide}
                                                    showPrices={showPrices}
                                                />
                                            </Box>
                                            <Box component="div" sx={{
                                                padding: 1,
                                                ['@media (max-width:800px)']: {
                                                    order: '10'
                                                }
                                            }}>
                                                <Button variant="contained" fullWidth onClick={onBuyShedButtonClick}>
                                                    <strong>Offerte aanvragen</strong>
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </>
                            }

                            {/* 3d render window */}
                            <Box component="div" sx={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                height: '100%',
                                width: 'calc(100% - 350px)',
                                ['@media (max-width:800px)']: {
                                    width: '100%',
                                    // height: '50%'
                                    height: arModeEnabled ? '100%' : '40%'
                                },
                                overflow: 'hidden',
                                zIndex: 100
                            }}>
                                {!arModeEnabled && <>
                                    <Box component="div" sx={{
                                        position: 'absolute',
                                        bottom: '40px',
                                        right: '10px',
                                        zIndex: 100
                                    }}>
                                        <XRButton
                                            onClick={viewInArButtonClickHandler}
                                            mode="AR"
                                            sessionInit={{
                                                requiredFeatures: ['hit-test'],
                                                optionalFeatures: ['dom-overlay', 'dom-overlay-for-handheld-ar'],
                                                domOverlay: { root: document.body }
                                            }}
                                            style={{
                                                border: 'none',
                                                lineHeight: 1,
                                                margin: 0,
                                                padding: '3px 7px',
                                                fontSize: '10px',
                                                opacity: 0.7
                                            }}
                                        >
                                            <ViewInArIcon sx={{ verticalAlign: 'middle', marginRight: 1 }} />
                                            bekijk in 3D (AR)
                                        </XRButton>
                                    </Box>

                                    {showPrices &&
                                        <Box component="div" sx={{
                                            position: 'absolute',
                                            left: 0,
                                            bottom: 0,
                                            marginBottom: 2,
                                            marginLeft: 2,
                                            zIndex: 100
                                        }}>
                                            <Price
                                                configuration={configuration}
                                                onOrderShedButtonClick={onBuyShedButtonClick}
                                            />
                                        </Box>
                                    }
                                    <Box
                                        component="div"
                                        sx={{
                                            zIndex: 100,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            pointerEvents: 'none',
                                            position: 'absolute',
                                            top: '20px',
                                            left: '20px',
                                            right: '20px'
                                        }}
                                    >
                                        <Box component="div" sx={{
                                            pointerEvents: 'all'
                                        }}>
                                            <Toolbar
                                                showDimensions={showDimensions}
                                                setShowDimensions={setShowDimensions}
                                                canUndo={canUndo}
                                                undoCallback={undo}
                                                canRedo={canRedo}
                                                redoCallback={redo}
                                                onMakeScreenshotClick={onMakeScreenshotClick}
                                            />
                                        </Box>
                                    </Box>
                                </>}

                                <Box component="div" sx={{
                                    position: 'absolute',
                                    right: '10px',
                                    bottom: '10px',
                                    zIndex: 100
                                }}>
                                    <ByLucusDotNl />
                                </Box>
                                <Renderer
                                    configuration={configuration}
                                    setConfiguration={setConfiguration}
                                    showDimensions={showDimensions}
                                    setShowDimensions={setShowDimensions}
                                    makeScreenshot={makeScreenshotForDownload}
                                    makeScreenshotForShedRequest={makeScreenshotForRequestShedPopup}
                                    screenshotMadeCallback={screenshotMadeCallback}
                                    doHitTests={false}
                                    arModeEnabled={arModeEnabled}
                                    onArSessionStart={arSessionsStartHandler}
                                    onArSessionEnd={arSessionsEndHandler}
                                    arObjectRotation={arObjectRotationDegrees}
                                    arObjectScale={arObjectScale}
                                    arCurrentMode={arCurrentMode}
                                    cameraFacingSideChangeCallback={cameraFacingSideChangeHandler}
                                    doInitialAnimation={doInitialAnimation}
                                    readyCallback={rendererReadyHandler}
                                    hitTestCallback={hitTestCallback}
                                />
                            </Box>
                        </Box>

                    </OrbitControlsContextProvider>
                </InteractableObjectContextProvider>
            </SelectedObjectMeshTopRight2DPositionContextProvider>
        </DevToolsContextProvider>
    </>
}

export default Configurator
