import { FC, useEffect } from 'react'
import { useThree } from '@react-three/fiber'
import { useInteractableObjectContext } from '../../interactable-object/context'
import { PerspectiveCamera, Renderer, Scene, Vector2, Vector3, WebGLRenderer } from 'three'
import { cloneDeep } from 'lodash'
import { shedTypeKeys } from '../../shed-types'
import { useOrbitControlsContext } from '../orbit-controls/context'

interface Props {
    shedTypeKey: typeof shedTypeKeys[number]
    makeScreenshot: boolean
    screenshotMadeCallback: (blob: Blob) => void
    showDimensions: boolean
    setShowDimensions: (bool: boolean) => void
}

const screenshotDimensions = {
    width: 1000,
    height: 600
}

type ScreenshotCameraPositions = {
    [K in typeof shedTypeKeys[number]]: [number, number, number]
}

const screenshotCameraPositions:ScreenshotCameraPositions = {
    'hardin': [7, 4, 7],
    'rotan': [7, 4, 7],
    'anson': [7, 4, 7],
    'anson-m': [7, 4, 7],
    'forsan': [7, 4.5, 7],
    'forsan-m': [7, 4.5, 7],
    'menard': [8, 5, 8],
    'menard-m': [8, 5, 8],
    'dryden': [9, 5, 9]
}

const ScreenShotterShedRequest:FC<Props> = (props) => {
    const { gl, scene, camera }: { gl: WebGLRenderer, scene: Scene, camera: PerspectiveCamera } = useThree()
    const { selectedObject, setSelectedObject } = useInteractableObjectContext()
    const { orbitControlsInstance } = useOrbitControlsContext()

    useEffect(() => {
        if (props.makeScreenshot) {
            // store old stuff
            const oldSize = new Vector2()
            gl.getSize(oldSize)


            const oldCameraPosition = camera.position.clone()
            const oldShowDimensions = props.showDimensions
            if (props.showDimensions) {
                props.setShowDimensions(false)
            }
            const oldSelectedObject = cloneDeep(selectedObject)
            //const oldOrbitControlsTarget = orbitControlsInstance.target.clone()
            if (selectedObject !== null) {
                setSelectedObject(null)
            }

            // a short delay to allow setSelectedObject and setShowDimensions to take effect
            // somewhat dirty yes I know
            window.setTimeout(() => {
                // prepare for making screenshot
                gl.setSize(screenshotDimensions.width, screenshotDimensions.height)
                camera.position.set(
                    screenshotCameraPositions[props.shedTypeKey][0],
                    screenshotCameraPositions[props.shedTypeKey][1],
                    screenshotCameraPositions[props.shedTypeKey][2],
                )
                camera.aspect = screenshotDimensions.width / screenshotDimensions.height
                camera.updateProjectionMatrix()

                orbitControlsInstance.update()

                // make the screenshot
                gl.render(scene, camera)
                gl.domElement.toBlob((blob) => {
                    props.screenshotMadeCallback(blob as Blob)
                }, 'image/png')

                // restore old stuff
                gl.setSize(oldSize.x, oldSize.y)
                //console.log(oldCameraPosition.x, oldCameraPosition.y, oldCameraPosition.z)
                camera.position.set(oldCameraPosition.x, oldCameraPosition.y, oldCameraPosition.z)
                camera.aspect = oldSize.x / oldSize.y
                camera.updateProjectionMatrix()
                //
                // orbitControlsInstance.target = oldOrbitControlsTarget
                // orbitControlsInstance.update()

                if (oldShowDimensions) {
                    props.setShowDimensions(oldShowDimensions)
                }
                if (oldSelectedObject !== null) {
                    setSelectedObject(selectedObject)
                }
            }, 50)
        }
    }, [props.makeScreenshot])

    return <></>
}

export default ScreenShotterShedRequest
