import { FC, useCallback, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ConfigurationInterface, SetConfigurationInterface } from '../configuration'
import ShedTypeSection from './section/shed-type'
import WindowsAndDoorsSection from './section/windows-and-doors'
import ColorsAndFinishSection from './section/colors-and-finish'
import RoofTypeSection from './section/roof-type'
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined'
import RoofingIcon from '@mui/icons-material/Roofing'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import SensorDoorOutlinedIcon from '@mui/icons-material/SensorDoorOutlined'
import sides from '../renderer/shed/sides'

const sections = ['shedType', 'windowsAndDoors', 'roof', 'colors']

interface Props {
    configuration: ConfigurationInterface
    setConfiguration: SetConfigurationInterface
    cameraIsFacingSide: typeof sides[number]
    showPrices: boolean
}

const ControlPanel:FC<Props> = ({ configuration, setConfiguration, cameraIsFacingSide, showPrices }) => {
    const [expandedSection, setExpandedSection] = useState<typeof sections[number]|null>('shedType')

    const handleExpandedSectionChange = useCallback((section: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedSection(isExpanded ? section : null)
    }, [])

    return <>
        <Accordion
            disableGutters
            expanded={expandedSection === 'shedType'}
            onChange={handleExpandedSectionChange('shedType')}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <HomeOutlinedIcon sx={{ marginRight: 1 }} />
                <Typography>
                    Type DUBLO
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ShedTypeSection
                    configuration={configuration}
                    setConfiguration={setConfiguration}
                    showPrices={showPrices}
                />
            </AccordionDetails>
        </Accordion>

        <Accordion
            sx={{
                marginTop: 1.5
            }}
            disableGutters
            expanded={expandedSection === 'windowsAndDoors'}
            onChange={handleExpandedSectionChange('windowsAndDoors')}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <SensorDoorOutlinedIcon sx={{ marginRight: 1 }} />
                <Typography>
                    Ramen en deuren
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <WindowsAndDoorsSection
                    configuration={configuration}
                    setConfiguration={setConfiguration}
                    cameraIsFacingSide={cameraIsFacingSide}
                    showPrices={showPrices}
                />
            </AccordionDetails>
        </Accordion>

        <Accordion
            sx={{
                marginTop: 1.5
            }}
            disableGutters
            expanded={expandedSection === 'roof'}
            onChange={handleExpandedSectionChange('roof')}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <RoofingIcon sx={{ marginRight: 1 }} />
                <Typography>
                    Dak
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <RoofTypeSection
                    configuration={configuration}
                    setConfiguration={setConfiguration}
                />
            </AccordionDetails>
        </Accordion>

        <Accordion
            sx={{
                marginTop: 1.5
            }}
            disableGutters
            expanded={expandedSection === 'colorsAndFinish'}
            onChange={handleExpandedSectionChange('colorsAndFinish')}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <PaletteOutlinedIcon sx={{ marginRight: 1 }} />
                <Typography>
                    Kleuren en afwerking
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ColorsAndFinishSection
                    configuration={configuration}
                    setConfiguration={setConfiguration}
                />
            </AccordionDetails>
        </Accordion>
    </>
}

export default ControlPanel
