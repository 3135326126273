import { BaseSyntheticEvent, FC, useCallback, useEffect, useRef } from 'react'
import { ConfigurationInterface, SetConfigurationInterface } from '../../configuration'
import { Box, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import roofTypes, { roofMaterialTypeKeys } from '../../roof-material-types'
import { cloneDeep } from 'lodash'

interface Props {
    configuration: ConfigurationInterface,
    setConfiguration: SetConfigurationInterface
}

const RoofTypeSection:FC<Props> = (props) => {
    const configurationRef = useRef(props.configuration)
    useEffect(() => {
        configurationRef.current = props.configuration
    }, [props.configuration])

    const setRoofType = (newType: typeof roofMaterialTypeKeys[number]) => {
        const newConfiguration = cloneDeep(configurationRef.current)
        newConfiguration.roofMaterialType = newType
        props.setConfiguration(newConfiguration)
    }

    const changeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setRoofType(event.target.value as typeof roofMaterialTypeKeys[number])
    }, [])

    const clickHandler = useCallback((event: BaseSyntheticEvent) => {
        setRoofType(event.target.getAttribute('data-roof-type-key') as typeof roofMaterialTypeKeys[number])
    }, [])

    return <>
        <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={changeHandler}
        >
            <Grid
                container
                spacing={1}
            >
                {roofTypes.map((roofType, index) => {
                    return <Grid
                        key={roofType.key}
                        item
                        xs={6}
                    >

                        {roofType.optionImageFilename === undefined &&
                            <Box
                                data-roof-type-key={roofType.key}
                                component="div"
                                sx={{
                                    backgroundColor: '#eee',
                                    paddingTop: '60%'
                                }}
                                onClick={clickHandler}
                            >
                            </Box>
                        }

                        {roofType.optionImageFilename !== undefined &&
                            <Box
                                data-roof-type-key={roofType.key}
                                component="div"
                                sx={{
                                    // backgroundColor: '#eee'
                                }}
                                onClick={clickHandler}
                            >
                                <img
                                    src={`${import.meta.env.VITE_DUBLO_APP_FILES_URL}/static/option-images/${roofType.optionImageFilename}`}
                                    alt={roofType.name}
                                    data-roof-type-key={roofType.key}
                                />
                            </Box>
                        }

                        <Box
                            component="div"
                            sx={{
                                textAlign: 'center'
                            }}
                        >
                            <FormControlLabel
                                value={roofType.key}
                                labelPlacement="bottom"
                                control={<Radio />}
                                label={<Typography variant="subtitle2">{roofType.name}</Typography>}
                                checked={roofType.key === props.configuration.roofMaterialType}
                                sx={{
                                    margin: 0
                                }}
                            />
                        </Box>

                    </Grid>
                })}
            </Grid>
        </RadioGroup>
    </>
}

export default RoofTypeSection