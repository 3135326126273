import { BaseSyntheticEvent, ChangeEvent, FC, useCallback } from 'react'
import { ConfigurationInterface, SetConfigurationInterface } from '../../../configuration'
import { cloneDeep } from 'lodash'
import colorsAndFinishes, { keys } from '../../../colors-and-finishes'
import { Box, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'

interface Props {
    configuration: ConfigurationInterface,
    setConfiguration: SetConfigurationInterface
}

const Roof:FC<Props> = (props) => {
    const setRoofColorOrFinish = (key: typeof keys.roof[number]) => {
        const newConfiguration = cloneDeep(props.configuration)
        newConfiguration.colorsAndFinish.roof = key
        props.setConfiguration(newConfiguration)
    }

    const roofChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setRoofColorOrFinish(event.target.value as typeof keys.roof[number])
    }

    const roofClickHandler = (event: BaseSyntheticEvent) => {
        setRoofColorOrFinish(event.target.getAttribute('data-key'))
    }

    return <>
        <Typography>
            <strong>Dak</strong>
        </Typography>

        <RadioGroup onChange={roofChangeHandler}>
            <Grid container spacing={1} sx={{
                marginTop: 1
            }}>
                {colorsAndFinishes.roof.map((colorOrFinish, index) => {
                    return <Grid key={index} item xs={4}>
                        <Box
                            component="div"
                            data-key={colorOrFinish.key}
                            sx={{
                                backgroundColor: colorOrFinish.exampleHexColor,
                                paddingTop: '60%'
                            }}
                            onClick={roofClickHandler}
                        >
                        </Box>

                        <Box
                            component="div"
                            sx={{
                                textAlign: 'center'
                            }}
                        >
                            <FormControlLabel
                                value={colorOrFinish.key}
                                labelPlacement="bottom"
                                control={<Radio />}
                                label={<Typography variant="subtitle2">{colorOrFinish.name}</Typography>}
                                checked={colorOrFinish.key === props.configuration.colorsAndFinish.roof}
                                sx={{
                                    margin: 0
                                }}
                            />
                        </Box>
                    </Grid>
                })}
            </Grid>
        </RadioGroup>
    </>
}

export default Roof
