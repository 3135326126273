import { FC } from 'react'
import { useFrame, useThree } from '@react-three/fiber'

const DimensionTextToCameraPointer:FC = () => {
    const { camera, scene } = useThree()

    useFrame(() => {
        scene.traverse((object) => {
            if (object.userData?.isDimensionText === true) {
                object.lookAt(camera.position)
            }
        })
    })

    return <></>
}

export default DimensionTextToCameraPointer
