import { Box, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import colorsAndFinishes, { keys } from '../../../colors-and-finishes'
import { cloneDeep } from 'lodash'
import { ChangeEvent, FC } from 'react'
import { ConfigurationInterface, SetConfigurationInterface } from '../../../configuration'

interface Props {
    configuration: ConfigurationInterface,
    setConfiguration: SetConfigurationInterface
}

const Details:FC<Props> = (props) => {
    const setDetailingColorOrFinish = (key: typeof keys.detailing[number]) => {
        const newConfiguration = cloneDeep(props.configuration)
        newConfiguration.colorsAndFinish.detailing = key
        props.setConfiguration(newConfiguration)
    }

    const detailingChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setDetailingColorOrFinish(event.target.value as typeof keys.detailing[number])
    }

    return <>
        <Typography>
            <strong>Detaillering</strong>
        </Typography>
        <Typography variant="subtitle2">
            Kozijnen, deuren en overheaddeuren
        </Typography>

        <RadioGroup onChange={detailingChangeHandler}>
            <Grid container spacing={1} sx={{
                marginTop: 1
            }}>
                {colorsAndFinishes.detailing.map((colorOrFinish, index) => {
                    return <Grid key={index} item xs={4}>
                        <Box
                            component="div"
                            sx={{
                                backgroundColor: colorOrFinish.exampleHexColor,
                                paddingTop: '60%'
                            }}
                            onClick={() => {
                                setDetailingColorOrFinish(colorOrFinish.key)
                            }}
                        >
                        </Box>

                        <Box
                            component="div"
                            sx={{
                                textAlign: 'center'
                            }}
                        >
                            <FormControlLabel
                                value={colorOrFinish.key}
                                labelPlacement="bottom"
                                control={<Radio />}
                                label={<Typography variant="subtitle2">{colorOrFinish.name}</Typography>}
                                checked={colorOrFinish.key === props.configuration.colorsAndFinish.detailing}
                                sx={{
                                    margin: 0
                                }}
                            />
                        </Box>
                    </Grid>
                })}
            </Grid>
        </RadioGroup>
    </>
}

export default Details
