import { FC } from 'react'
import { ConfigurationInterface, SetConfigurationInterface } from '../../../configuration'
import { Box, Typography } from '@mui/material'
import Roof from './roof'
import Walls from './walls'
import ConcretePlinth from './concrete-plinth'
import Details from './details'
import WindFeather from './wind-feather'

interface Props {
    configuration: ConfigurationInterface,
    setConfiguration: SetConfigurationInterface
}

/**
 * Todo use useCallback() for change events, optimize performance yo!
 * @param configuration
 * @param setConfiguration
 * @constructor
 */
const ColorsAndFinishSection:FC<Props> = ({ configuration, setConfiguration }) => {
    return <>
        <Typography>
            De kleuren in het 3D voorbeeld kunnen afwijken van de daadwerkelijke kleuren. We sturen je graag een kleurenstaal op, neem hiervoor contact met ons op.
        </Typography>

        <Box component="div" sx={{
            marginTop: 2
        }}>
            <Roof configuration={configuration} setConfiguration={setConfiguration} />
        </Box>

        <Box component="div" sx={{
            marginTop: 2
        }}>
            <WindFeather configuration={configuration} setConfiguration={setConfiguration} />
        </Box>

        <Box component="div" sx={{
            marginTop: 2
        }}>
            <Walls configuration={configuration} setConfiguration={setConfiguration} />
        </Box>

        <Box component="div" sx={{
            marginTop: 2
        }}>
            <Details configuration={configuration} setConfiguration={setConfiguration} />
        </Box>

        <Box component="div" sx={{
            marginTop: 2
        }}>
            <ConcretePlinth configuration={configuration} setConfiguration={setConfiguration} />
        </Box>

    </>
}

export default ColorsAndFinishSection
