const roofColorOrFinishKeys = ['anthracite', 'black', 'terracotta'] as const
const wallColorOrFinishKeys = ['anthracite', 'black', 'juniperGreen'] as const
const detailingColorOrFinishKeys = ['anthracite', 'black', 'white'] as const
const windFeatherColorOrFinishKeys = ['anthracite', 'black', 'white'] as const
const concretePlinthColorOrFinishKeys = ['concreteGrey', 'redStoneMotif', 'anthraciteGravel'] as const

const keys = {
    roof: roofColorOrFinishKeys,
    wall: wallColorOrFinishKeys,
    windFeather: windFeatherColorOrFinishKeys,
    detailing: detailingColorOrFinishKeys,
    concretePlinth: concretePlinthColorOrFinishKeys
}

interface ColorOrFinishOption {
    key: string
    name: string
    exampleHexColor?: `#${string}`,
    sceneHexColor?: `#${string}`,
    exampleImgPath?: string
}

interface RoofColorOrFinishOption extends ColorOrFinishOption { key: typeof roofColorOrFinishKeys[number] }
interface WallColorOrFinishOption extends ColorOrFinishOption { key: typeof wallColorOrFinishKeys[number] }
interface DetailingColorOrFinishOption extends ColorOrFinishOption { key: typeof detailingColorOrFinishKeys[number] }
interface WindFeatherColorOrFinishOption extends ColorOrFinishOption { key: typeof windFeatherColorOrFinishKeys[number] }
interface ConcretePlinthColorOrFinishOption extends ColorOrFinishOption { key: typeof concretePlinthColorOrFinishKeys[number] }

interface ColorsAndFinish {
    roof: RoofColorOrFinishOption[]
    wall: WallColorOrFinishOption[]
    detailing: DetailingColorOrFinishOption[]
    concretePlinth: ConcretePlinthColorOrFinishOption[]
    windFeather: WindFeatherColorOrFinishOption[]
}

const colorsAndFinishes: ColorsAndFinish = {
    roof:  [{
        key: 'anthracite',
        name: 'Antraciet',
        exampleHexColor: '#404244',
        sceneHexColor: '#404244'
    }, {
        key: 'black',
        name: 'Zwart',
        exampleHexColor: '#0e0e10',
        sceneHexColor: '#333333'
    }, {
        key: 'terracotta',
        name: 'Terracotta',
        exampleHexColor: '#87584a',
        sceneHexColor: '#87584a'
    }],
    wall: [{
        key: 'anthracite',
        name: 'Antraciet',
        exampleHexColor: '#404244',
        sceneHexColor: '#404244'
    }, {
        key: 'black',
        name: 'Zwart',
        exampleHexColor: '#0e0e10',
        sceneHexColor: '#333333'
    }, {
        key: 'juniperGreen',
        name: 'Juniper Green',
        exampleHexColor: '#364427',
        sceneHexColor: '#364427'
    }],
    detailing: [{
        key: 'anthracite',
        name: 'Antraciet',
        exampleHexColor: '#404244',
        sceneHexColor: '#404244'
    }, {
        key: 'black',
        name: 'Zwart',
        exampleHexColor: '#000000',
        sceneHexColor: '#333333'
    }, {
        key: 'white',
        name: 'Wit',
        exampleHexColor: '#ffffff',
        sceneHexColor: '#dddddd'
    }],
    windFeather: [{
        key: 'anthracite',
        name: 'Antraciet',
        exampleHexColor: '#404244',
        sceneHexColor: '#404244'
    }, {
        key: 'black',
        name: 'Zwart',
        exampleHexColor: '#0e0e10',
        sceneHexColor: '#333333'
    }, {
        key: 'white',
        name: 'Wit',
        exampleHexColor: '#ffffff',
        sceneHexColor: '#dddddd'
    }],
    concretePlinth: [{
        key: 'concreteGrey',
        name: 'Beton grijs',
        exampleHexColor: '#cbc9c9',
        sceneHexColor: '#cbc9c9'
    }, {
        key: 'redStoneMotif',
        name: 'Rood steen motief',
        // exampleHexColor: '#770505',
        sceneHexColor: '#770505',
        exampleImgPath: '/static/color-or-finish-examples/red-stone-motif.png'
    }, {
        key: 'anthraciteGravel',
        name: 'Antraciet grind',
        exampleHexColor: '#838383',
        sceneHexColor: '#838383',
        exampleImgPath: '/static/color-or-finish-examples/anthracite-gravel.png'
    }]
}

export default colorsAndFinishes
export { keys }
