import { FC, useEffect, useMemo, useState } from 'react'
import { useThree, useFrame } from '@react-three/fiber'
import { Vector3 } from 'three'
import { radToDeg } from 'three/src/math/MathUtils'
import sides from '../shed/sides'

interface Props {
    onFacingSideChange: (direction: typeof sides[number]) => void
}

const CameraFacingShedSideUpdater:FC<Props> = (props) => {
    const { camera } = useThree()
    const targetVector = useMemo(() => new Vector3(), [])
    const [currentSide, setCurrentSide] = useState<typeof sides[number]>('front')

    useFrame(() => {
        camera.getWorldDirection(targetVector)
        let angle = radToDeg(Math.atan2(targetVector.x, targetVector.z))

        if (angle < -135 || angle > 135) {
            if (currentSide !== 'front') setCurrentSide('front')
        } else if (angle > -135 && angle < -45) {
            if (currentSide !== 'right') setCurrentSide('right')
        } else if (angle > -45 && angle < 45) {
            if (currentSide !== 'back') setCurrentSide('back')
        } else if (angle > 45 && angle < 135) {
            if (currentSide !== 'left') setCurrentSide('left')
        }
    })

    useEffect(() => {
        props.onFacingSideChange(currentSide)
    }, [currentSide])

    return <></>
}

export default CameraFacingShedSideUpdater
