const roofMaterialTypeKeys = [
    'falk-1100-tr',
    'falk-1000-gl'
] as const

interface RoofType {
    /**
     * The name visible for the configurator users
     */
    name: string
    /**
     * Must be unique, for internal use, radio button value for example
     */
    key: typeof roofMaterialTypeKeys[number]
    optionImageFilename?: string,
}

const RoofMaterialTypes: RoofType[] = [{
    name: 'Trapezium',
    key: 'falk-1100-tr',
    optionImageFilename: 'paneel-tr1000.png'
}, {
    name: 'Golfplaat',
    key: 'falk-1000-gl',
    optionImageFilename: 'paneel-golfplaat.png'
}]

export default RoofMaterialTypes
export { roofMaterialTypeKeys }
