import { FC, useEffect, useMemo } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import { Material, Mesh, MeshPhongMaterial, Vector3 } from 'three'
import { useDevToolsContext } from '../../../../dev-tools/context'
import { WallFeature, WallFeatures } from '../../../../wall-features'
import mooColor from 'moo-color'
import DimensionVisualizer from '../../../dimension-visualizer/dimension'
import { ChildPropsInterface } from '../interactive-feature-wrapper/child-props'

interface Props extends ChildPropsInterface {
    detailingMaterial: Material
}

type GLTFResult = GLTF & {
    nodes: {
        backside: Mesh,
        frame: Mesh,
        rails: Mesh,
        rubbers: Mesh
    }
}

const OverheadDoor2Dot5x2Dot5:FC<Props> = (props) => {
    const { nodes } = useGLTF(`${import.meta.env.VITE_BASE_URL}/static/3d/overhead-door-2.5x2.5.glb`) as GLTFResult
    const { options: devToolsOptions } = useDevToolsContext()

    const wallFeature = useMemo(() => {
       return WallFeatures.find(feature => feature.key === 'overheadDoor2.5x2.5') as WallFeature
    }, [])

    const materials = useMemo(() => {
        const backside = new MeshPhongMaterial({
            color: '#5b5b5b',
            wireframe: devToolsOptions.showWireframes
        })

        const frame = props.detailingMaterial.clone() as MeshPhongMaterial
        frame.wireframe = devToolsOptions.showWireframes

        const rails = new MeshPhongMaterial({
            color: '#777777',
            wireframe: devToolsOptions.showWireframes
        })

        const rubbers = new MeshPhongMaterial({
            color: '#2f2f2f',
            wireframe: devToolsOptions.showWireframes
        })

        return { backside, frame, rails, rubbers }
    }, [devToolsOptions.showWireframes, props.detailingMaterial])

    useEffect(() => {
        Object.entries(materials).forEach(([key, material]) => {
            material.userData = {...material.userData, defaultColor: '#' + material.color.getHexString()}
        })
    }, [props.detailingMaterial])

    useEffect(() => {
        Object.entries(materials).forEach(([key, material]) => {
            if (props.isHovered || props.isCollidingWithOtherObjects) {
                let theColor = new mooColor(material.userData.defaultColor)

                if (props.isHovered) {
                    theColor.blacken(20)
                }

                if (props.isCollidingWithOtherObjects) {
                    theColor = theColor.mix(new mooColor('#ff0000'))
                }

                material.color.set(theColor.toHex())
            } else {
                material.color.set(material.userData.defaultColor)
            }
        })
    }, [props.isHovered, props.isCollidingWithOtherObjects, materials])

    return <>
        <group>
            {props.showDimensions &&
                <group>
                    <DimensionVisualizer
                        name="Width"
                        pointA={new Vector3(0, wallFeature.dimensions.heightMeter, 0)}
                        pointB={new Vector3(wallFeature.dimensions.widthMeter, wallFeature.dimensions.heightMeter, 0)}
                        helperLinesDirection={new Vector3(0, 0, 1)}
                        helperLinesLength={0.5}
                        textAnchorOffsetY={0.05}
                        text={wallFeature.dimensions.widthMeter.toLocaleString('nl', {
                            maximumFractionDigits: 2
                        }) + ' m'}
                    />
                    <DimensionVisualizer
                        name="Height"
                        pointA={new Vector3(0, 0, 0)}
                        pointB={new Vector3(0, wallFeature.dimensions.heightMeter, 0)}
                        helperLinesDirection={new Vector3(0, 0, 1)}
                        helperLinesLength={0.5}
                        textAnchorPositionX="right"
                        textAnchorOffsetX={-0.05}
                        text={wallFeature.dimensions.heightMeter.toLocaleString('nl', {
                            maximumFractionDigits: 2
                        }) + ' m'}
                    />
                </group>
            }

            <group position={[wallFeature.feature3DObject.offset.x,
                wallFeature.feature3DObject.offset.y,
                wallFeature.feature3DObject.offset.z
            ]}>
                <mesh castShadow geometry={nodes.frame.geometry} material={materials.frame} />
                <mesh castShadow geometry={nodes.rails.geometry} material={materials.rails} />
                <mesh castShadow geometry={nodes.rubbers.geometry} material={materials.rubbers} />
                <mesh castShadow geometry={nodes.backside.geometry} material={materials.backside} />
            </group>
        </group>
    </>
}

export default OverheadDoor2Dot5x2Dot5
