import { OrbitControls as ThreeOrbitControls } from '@react-three/drei'
import { FC, useEffect, useRef } from 'react'
import { useOrbitControlsContext } from './context'
import { useThree } from '@react-three/fiber'
import { degToRad } from 'three/src/math/MathUtils'

const OrbitControls:FC = () => {
    const { enabled, autoRotateEnabled, setAutoRotateEnabled } = useOrbitControlsContext()
    const { camera } = useThree()
    const { setOrbitControlsInstance } = useOrbitControlsContext()

    const orbitControlsRef = useRef()

    useEffect(() => {
        if (orbitControlsRef.current !== undefined) {
            setOrbitControlsInstance(orbitControlsRef.current)
        }
    }, [])

    const onStart = () => {
        if (autoRotateEnabled) {
            setAutoRotateEnabled(false)
        }
    }

    return <>
        <ThreeOrbitControls
            // @ts-ignore
            ref={orbitControlsRef}
            camera={camera}
            // target={new Vector3(5, 0, -7.5)}
            enabled={enabled}
            // enableDamping={false}
            enablePan={false}
            dampingFactor={0.2}
            rotateSpeed={0.4}
            autoRotate={autoRotateEnabled}
            maxPolarAngle={degToRad(85)}
            autoRotateSpeed={-2}
            onStart={onStart}
            maxDistance={25}
            minDistance={10}
        />
    </>
}

export default OrbitControls
