import { FC, useEffect, useRef } from 'react'
import { ConfigurationInterface, SetConfigurationInterface } from '../configuration'
import { useInteractableObjectContext } from '../interactable-object/context'
import { Button, ListItemIcon, ListItemText, MenuItem, Popper } from '@mui/material'
import useSelectedObjectMeshTopRight2DPositionContext from './mesh-top-right-2d-position/context'
import Menu from '@mui/material/Menu'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

interface Props {
    configuration: ConfigurationInterface,
    setConfiguration: SetConfigurationInterface
}

const SelectedObjectMenu:FC<Props> = (props) => {
    const { selectedObject, setSelectedObject } = useInteractableObjectContext()
    const { position } = useSelectedObjectMeshTopRight2DPositionContext()

    // let top = 0
    // let left = 0
    //
    // if (selectedObject !== null && selectedObject.position2D !== undefined) {
    //     left = selectedObject.position2D[0]
    //     top = selectedObject.position2D[1]
    // }

    const virtualReference = {
        getBoundingClientRect() {
            let left = position[0]
            let top = position[1]

            return {
                top: top,
                left: left,
                bottom: top,
                right: left,
                width: 0,
                height: 0
            }
        },
    }

    const configurationRef = useRef(props.configuration)

    useEffect(() => {
        configurationRef.current = props.configuration
    }, [props.configuration])

    const deleteClickHandler = () => {
        const newConfiguration = {...configurationRef.current}
        newConfiguration.features = newConfiguration.features.filter(configurationFeature => configurationFeature.uuid !== selectedObject?.uuid)
        props.setConfiguration(newConfiguration)
        setSelectedObject(null)
    }

    const closeHandler = () => {
        setSelectedObject(null)
    }

    // return <>
    //     <Menu
    //         componentsProps={{
    //             backdrop: {
    //                 // invisible: true,
    //                 hidden: true
    //             }
    //         }}
    //         components={{
    //
    //         }}
    //         hideBackdrop
    //         disableScrollLock
    //         disablePortal
    //         onClose={closeHandler}
    //         open={selectedObject !== null}
    //         anchorReference="anchorPosition"
    //         anchorPosition={{
    //             top: position[1],
    //             left: position[0]
    //         }}
    //     >
    //         <MenuItem onClick={deleteClickHandler}>
    //             <ListItemIcon>
    //                 <DeleteOutlineIcon fontSize="small" />
    //             </ListItemIcon>
    //             <ListItemText>Verwijderen</ListItemText>
    //         </MenuItem>
    //     </Menu>
    // </>

    // @ts-ignore
    return <Popper style={{ zIndex: 1000 }} disablePortal open={selectedObject !== null} anchorEl={virtualReference} placement="bottom-start">
        <Button
            onClick={deleteClickHandler}
            startIcon={<DeleteOutlineIcon fontSize="small" />}
            variant="contained"
        >
            Verwijderen
        </Button>
    </Popper>
}

export default SelectedObjectMenu
