import { ConfigurationInterface, ShedWallFeatureInterface } from './configuration'
import sides from './renderer/shed/sides'
import { generateInteractableObjectUuid } from './interactable-object/context'
import { shedTypeKeys } from './shed-types'

const heavyConfiguration:ConfigurationInterface = {
    shedType: 'dryden',
    roofMaterialType: 'falk-1000-gl',
    colorsAndFinish: {
        roof: 'black',
        wall: 'black',
        detailing: 'white',
        concretePlinth: 'redStoneMotif',
        windFeather: 'white'
    },
    features: []
}

for (const side of sides) {
    switch (side) {
        case 'back':
        case 'front':
            for (let i = 1; i <= 5; i ++) {
                heavyConfiguration.features.push({
                    side,
                    featureKey: 'door',
                    distanceLeftMeter: i * 1.5,
                    uuid: generateInteractableObjectUuid()
                })
            }
            break
        case 'left' :
        case 'right' :
            for (let i = 1; i <= 8; i ++) {
                heavyConfiguration.features.push({
                    side,
                    featureKey: 'door',
                    distanceLeftMeter: i * 1.5,
                    uuid: generateInteractableObjectUuid()
                })
            }
            break
    }
}

const defaultConfiguration:ConfigurationInterface = {
    shedType: 'forsan',
    roofMaterialType: 'falk-1000-gl',
    colorsAndFinish: {
        roof: 'anthracite',
        wall: 'anthracite',
        detailing: 'black',
        concretePlinth: 'concreteGrey',
        windFeather: 'black'
    },
    features: [],
}

const defaultConfigurationInformalCareHome:ConfigurationInterface = {
    shedType: 'forsan-m',
    roofMaterialType: 'falk-1000-gl',
    colorsAndFinish: {
        roof: 'terracotta',
        wall: 'anthracite',
        detailing: 'anthracite',
        concretePlinth: 'redStoneMotif',
        windFeather: 'white'
    },
    features: [],
}

interface DefaultShedTypeFeatures {
    shedType: typeof shedTypeKeys[number]
    features: ShedWallFeatureInterface[]
}

const defaultShedTypeFeatures:DefaultShedTypeFeatures[] = [{
    shedType: 'hardin',
    features: [{
        side: 'right',
        featureKey: 'door',
        distanceLeftMeter: 1.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: 'right',
        featureKey: 'window',
        distanceLeftMeter: 4.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: 'front',
        featureKey: 'overheadDoor2.5x2.5',
        distanceLeftMeter: 0.25,
        uuid: generateInteractableObjectUuid()
    }]
}, {
    shedType: 'rotan',
    features: [{
        side: 'right',
        featureKey: 'door',
        distanceLeftMeter: 1.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: 'right',
        featureKey: 'window',
        distanceLeftMeter: 4.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: 'front',
        featureKey: 'overheadDoor2.5x2.5',
        distanceLeftMeter: 0.25,
        uuid: generateInteractableObjectUuid()
    }]
}, {
    shedType: 'anson',
    features: [{
        side: 'right',
        featureKey: 'door',
        distanceLeftMeter: 1.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: 'right',
        featureKey: 'window',
        distanceLeftMeter: 5.75,
        uuid: generateInteractableObjectUuid()
    }, {
        side: 'front',
        featureKey: 'overheadDoor3x3',
        distanceLeftMeter: 1,
        uuid: generateInteractableObjectUuid()
    }]
}, {
    shedType: 'anson-m',
    features: [{
        side: 'right',
        featureKey: 'door',
        distanceLeftMeter: 1.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: 'right',
        featureKey: 'window',
        distanceLeftMeter: 5.75,
        uuid: generateInteractableObjectUuid()
    }, {
        side: 'front',
        featureKey: 'doubleDoorWithSidePanels',
        distanceLeftMeter: 1,
        uuid: generateInteractableObjectUuid()
    }]
}, {
    shedType: 'forsan',
    features: [{
        side: 'right',
        featureKey: 'door',
        distanceLeftMeter: 1.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: 'right',
        featureKey: 'window',
        distanceLeftMeter: 5.75,
        uuid: generateInteractableObjectUuid()
    }, {
        side: 'front',
        featureKey: 'overheadDoor3x3',
        distanceLeftMeter: 2.25,
        uuid: generateInteractableObjectUuid()
    }]
}, {
    shedType: 'forsan-m',
    features: [{
        side: 'right',
        featureKey: 'door',
        distanceLeftMeter: 1.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: 'right',
        featureKey: 'window',
        distanceLeftMeter: 5.75,
        uuid: generateInteractableObjectUuid()
    }, {
        side: 'front',
        featureKey: 'doubleDoorWithSidePanels',
        distanceLeftMeter: 2.25,
        uuid: generateInteractableObjectUuid()
    }]
}, {
    shedType: 'menard',
    features: [{
        side: 'right',
        featureKey: 'door',
        distanceLeftMeter: 1.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: 'right',
        featureKey: 'window',
        distanceLeftMeter: 9,
        uuid: generateInteractableObjectUuid()
    }, {
        side: 'front',
        featureKey: 'overheadDoor3x3',
        distanceLeftMeter: 2.5,
        uuid: generateInteractableObjectUuid()
    }]
}, {
    shedType: 'menard-m',
    features: [{
        side: 'right',
        featureKey: 'door',
        distanceLeftMeter: 1.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: 'right',
        featureKey: 'window',
        distanceLeftMeter: 9,
        uuid: generateInteractableObjectUuid()
    }, {
        side: 'front',
        featureKey: 'doubleDoorWithSidePanels',
        distanceLeftMeter: 2.5,
        uuid: generateInteractableObjectUuid()
    }]
}, {
    shedType: 'dryden',
    features: [{
        side: 'right',
        featureKey: 'door',
        distanceLeftMeter: 1.5,
        uuid: generateInteractableObjectUuid()
    }, {
        side: 'right',
        featureKey: 'window',
        distanceLeftMeter: 10.25,
        uuid: generateInteractableObjectUuid()
    }, {
        side: 'front',
        featureKey: 'overheadDoor3x3',
        distanceLeftMeter: 3.5,
        uuid: generateInteractableObjectUuid()
    }]
}]

export { defaultConfiguration, defaultShedTypeFeatures, defaultConfigurationInformalCareHome }
