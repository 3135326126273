import { FC, useMemo } from 'react'
import { ExtrudeBufferGeometry, Material, MathUtils, Shape } from 'three'
import { degToRad } from 'three/src/math/MathUtils'
import { ThreeEvent } from '@react-three/fiber/dist/declarations/src/core/events'
import { useDevToolsContext } from '../../../dev-tools/context'

let points : [number, number][] = []

const steps = 24
const waveLengthMm = 167
const amplitudeMm = 20

// wow Edwin, such math :D
for (let step = 0; step <= steps; step ++) {
    const distanceX = (step / steps) * waveLengthMm
    const degrees = (step / steps) * 360
    points.push([distanceX, amplitudeMm * Math.sin(degToRad(degrees))])
}

const getPoints = (widthMm: number, thicknessMm: number): [number, number][] => {
    const array: [number, number][] = []
    let partsDone = 0
    let maxX = points[points.length - 1][0]
    let continuePlottingLines = true

    while (continuePlottingLines) {
        points.forEach(((point, key) => {
            if (!continuePlottingLines) return

            if ((partsDone * maxX) + point[0] <= widthMm) {
                array.push([(partsDone * maxX) + point[0], point[1]])
            } else {
                const previousPoint = array[array.length - 1]
                const newVirtualPoint = [(partsDone * maxX) + point[0], point[1]]
                const distanceBetweenLastPointAndCut = widthMm - previousPoint[0]
                const xRatio = distanceBetweenLastPointAndCut / (newVirtualPoint[0] - previousPoint[0])

                const newPointX = widthMm
                let newPointY

                if (previousPoint[1] === newVirtualPoint[1]) {
                    newPointY = previousPoint[1]
                } else if (previousPoint[1] > newVirtualPoint[1]) {
                    newPointY = previousPoint[1] - (xRatio * previousPoint[1])
                } else {
                    newPointY = previousPoint[1] + (xRatio * newVirtualPoint[1])
                }

                array.push([newPointX, newPointY])
                continuePlottingLines = false
            }
        }))

        partsDone ++
    }

    let highestPoint = 0
    points.forEach(coords => {
        if (coords[1] > highestPoint) {
            highestPoint = coords[1]
        }
    })


    const plateThickness = thicknessMm - highestPoint

    // close it
    array.push([widthMm, 0 - plateThickness])
    array.push([0, 0 - plateThickness])

    return array
}

interface Props {
    widthMeter: number
    heightMeter: number
    thicknessMeter: number
    material: Material
}

const Falk1000Gl:FC<Props> = (props) => {
    const { options } = useDevToolsContext()

    const clickHandler = (event: ThreeEvent<MouseEvent>) => {
        if (options.logEventsToConsole) {
            console.log('onClick', event)
        }
        event.stopPropagation()
    }

    const pointerOverHandler = (event:ThreeEvent<MouseEvent>) => {
        if (options.logEventsToConsole) {
            console.log('onPointerOver', event)
        }
        event.stopPropagation()
    }

    // the plate is the solid square below the lowest point
    const { bVar } = useMemo(() => {
        let highestPoint = 0
        points.forEach(coords => {
            if (coords[1] > highestPoint) {
                highestPoint = coords[1]
            }
        })

        const plateThicknessMeter = ((props.thicknessMeter * 1000) - highestPoint) / 1000
        const aVar = props.thicknessMeter - plateThicknessMeter
        const bVar = (props.thicknessMeter / 2) - aVar

        return { bVar }
    }, [props.thicknessMeter])

    const geometry = useMemo(() => {
        const shape = new Shape()
        shape.autoClose = false

        const points = getPoints(props.widthMeter * 1000, props.thicknessMeter * 1000)
        points.forEach(point => {
            shape.lineTo(point[0] / 1000, point[1] / 1000)
        })

        const extrudeSettings = {
            depth: props.heightMeter,
            bevelEnabled: false
        }

        const geometry = new ExtrudeBufferGeometry(shape, extrudeSettings)
        return geometry
    }, [props.widthMeter, props.heightMeter])

    return <>
        <group
            rotation={[MathUtils.degToRad(90), 0, 0]}
            position={[-(props.widthMeter /2), props.heightMeter / 2, bVar]}
        >
            <mesh
                geometry={geometry}
                material={props.material}
                castShadow
                onClick={clickHandler}
                onPointerOver={pointerOverHandler}
            />
        </group>

    </>
}

export default Falk1000Gl

