import { ChangeEvent, FC } from 'react'
import { ConfigurationInterface, SetConfigurationInterface } from '../../../configuration'
import { cloneDeep } from 'lodash'
import colorsAndFinishes, { keys } from '../../../colors-and-finishes'
import { Box, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'

interface Props {
    configuration: ConfigurationInterface,
    setConfiguration: SetConfigurationInterface
}

const Walls:FC<Props> = (props) => {
    const setWallColorOrFinish = (key: typeof keys.wall[number]) => {
        const newConfiguration = cloneDeep(props.configuration)
        newConfiguration.colorsAndFinish.wall = key
        props.setConfiguration(newConfiguration)
    }

    const wallChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setWallColorOrFinish(event.target.value as typeof keys.wall[number])
    }

    return <>
        <Typography>
            <strong>Gevel</strong>
        </Typography>
        <Typography variant="subtitle2">
            Wanden
        </Typography>

        <RadioGroup onChange={wallChangeHandler}>
            <Grid container spacing={1} sx={{
                marginTop: 1
            }}>
                {colorsAndFinishes.wall.map((colorOrFinish, index) => {
                    return <Grid key={index} item xs={4}>
                        <Box
                            component="div"
                            sx={{
                                backgroundColor: colorOrFinish.exampleHexColor,
                                paddingTop: '60%'
                            }}
                            onClick={() => {
                                setWallColorOrFinish(colorOrFinish.key)
                            }}
                        >
                        </Box>

                        <Box
                            component="div"
                            sx={{
                                textAlign: 'center'
                            }}
                        >
                            <FormControlLabel
                                value={colorOrFinish.key}
                                labelPlacement="bottom"
                                control={<Radio />}
                                label={<Typography variant="subtitle2">{colorOrFinish.name}</Typography>}
                                checked={colorOrFinish.key === props.configuration.colorsAndFinish.wall}
                                sx={{
                                    margin: 0
                                }}
                            />
                        </Box>
                    </Grid>
                })}
            </Grid>
        </RadioGroup>
    </>
}

export default Walls