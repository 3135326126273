import { FC, MouseEvent, ReactNode, useEffect, useMemo, useRef } from 'react'
import { ConfigurationInterface, SetConfigurationInterface } from '../../configuration'
import {
    Divider,
    Typography,
    Grid,
    Box,Button
} from '@mui/material'
import { WallFeatures } from '../../wall-features'
import AddIcon from '@mui/icons-material/Add'
import sides from '../../renderer/shed/sides'
import { generateInteractableObjectUuid } from '../../interactable-object/context'
import { cloneDeep } from 'lodash'
import { wallFeatureKeys } from '../../wall-features'

interface Props {
    configuration: ConfigurationInterface
    setConfiguration: SetConfigurationInterface
    cameraIsFacingSide: typeof sides[number]
    showPrices: boolean
}

const WindowsAndDoorsSection:FC<Props> = (props) => {
    const configurationRef = useRef(props.configuration)

    useEffect(() => {
        configurationRef.current = props.configuration
    }, [props.configuration])

    const addFeatureButtonClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
        const newConfiguration = cloneDeep(configurationRef.current)
        newConfiguration.features.push({
            featureKey: event.currentTarget.getAttribute('data-feature-key') as typeof wallFeatureKeys[number],
            distanceLeftMeter: 1,
            side: props.cameraIsFacingSide,
            uuid: generateInteractableObjectUuid()
        })
        props.setConfiguration(newConfiguration)
    }

    const wallFeatureGridElements: ReactNode = useMemo(() => {
        return WallFeatures.map((wallFeature, index) => {
            let featureDisabled = false

            if (props.configuration.shedType === 'rotan' || props.configuration.shedType === 'hardin') {
                if (wallFeature.key === 'overheadDoor3x3') {
                    featureDisabled = true
                }
            }

            if (wallFeature.key === 'overheadDoor3x3' && (props.cameraIsFacingSide === 'left' || props.cameraIsFacingSide === 'right')) {
                featureDisabled = true
            }

            return <Grid item xs={6} key={wallFeature.key} sx={{
                display: 'flex'
            }}>
                <Box component="div" sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}>
                    <Box component="div">
                        {wallFeature.optionImageFilename !== undefined &&
                            <Box
                                data-disabled={featureDisabled ? 'true' : 'false'}
                                data-feature-key={wallFeature.key}
                                component="div"
                                sx={{
                                    textAlign: 'center',
                                    backgroundColor: '#eee',
                                    padding: '10px 0'
                                }}
                            >
                                <img
                                    src={`${import.meta.env.VITE_DUBLO_APP_FILES_URL}/static/option-images/${wallFeature.optionImageFilename}`}
                                    alt={wallFeature.name}
                                    data-feature-key={wallFeature.key}
                                    style={{
                                        maxWidth: '100px',
                                        display: 'inline-block'
                                    }}
                                />
                            </Box>
                        }

                        {wallFeature.optionImageFilename === undefined &&
                            <Box
                                data-disabled={featureDisabled ? 'true' : 'false'}
                                data-feature-key={wallFeature.key}
                                component="div"
                                sx={{
                                    backgroundColor: '#eee',
                                    paddingTop: '60%'
                                }}
                            />
                        }

                        <Box component="div" textAlign="center" marginTop={1}>
                            <Typography variant="subtitle2">
                                <strong>{wallFeature.name}</strong>
                            </Typography>
                        </Box>

                        {props.showPrices &&
                            <Box component="div" marginTop={0.6}>
                                <Typography variant="subtitle2" textAlign="center" color="primary">
                                    &euro; {wallFeature.priceEuro.toLocaleString('nl-NL', {maximumFractionDigits: 0})},-
                                    excl. BTW
                                </Typography>
                                <Typography variant="subtitle2" sx={{
                                    color: '#666666',
                                    fontSize: '9px',
                                    textAlign: 'center'
                                }}>
                                    &euro; {(wallFeature.priceEuro * 1.21).toLocaleString('nl-NL', {maximumFractionDigits: 0})},-
                                    incl. BTW
                                </Typography>
                            </Box>
                        }
                    </Box>

                    <Box component="div" marginTop={1}>
                        <Button
                            fullWidth
                            variant="contained"
                            startIcon={<AddIcon />}
                            data-feature-key={wallFeature.key}
                            onClick={addFeatureButtonClickHandler}
                            disabled={featureDisabled}
                        >
                            Toevoegen
                        </Button>
                    </Box>
                </Box>




            </Grid>
        })
    }, [props.configuration.shedType, props.cameraIsFacingSide])

    return <>
        <Typography>
            Klik op een raam of deur in het 3D-beeld om deze te wijzigen of te verwijderen.<br />
            Versleep een raam of deur om de positie te wijzigen.
        </Typography>

        <Divider sx={{
            marginTop: 2,
            marginBottom: 2
        }} />

        <Typography>
            <strong>Raam of deur toevoegen</strong>
        </Typography>

        <Grid container spacing={2} sx={{
            marginTop: 1,
            // alignItems: 'stretch'
        }}>
            {wallFeatureGridElements}
        </Grid>
    </>
}

export default WindowsAndDoorsSection
